* {
  box-sizing: border-box;
}

body {
  font-family: 'Roboto',sans-serif!important;
  color: #000;
  background-color: #010E21!important;
}

.container {
  max-width: 1240px !important;
  margin: 0 auto;
  padding: 0 40px !important;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.app{
  position: relative;
  -webkit-transition: opacity .3s;
  -o-transition: opacity .3s;
  transition: opacity .3s;
}

.wrapper {
  max-width: 1240px;
  min-height: 100%;
  margin: 0 auto;
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px rgba(0,0,0,.15);
  box-shadow: 0 0 15px rgba(0,0,0,.15);
  z-index: 1;
  position: relative;
}

.spinner {
  margin-left: 50%;
}

.spinner.text-success{
  color:#e76d0a !important;
}

.row {
  margin-left: 0!important;
  margin-right: 0!important;
}

.header{
  background: url(/public/image/bg_menu.png) left bottom no-repeat transparent;
  font-weight: 300;
  position: relative;
  padding: 15px 40px;
  display: flex;
  height: 122px;
}

header .header-logo {
  display: block;
  width: 190px;
  height: 120px;
  position: relative;
}

.header-menu__navigation {
  display: none;
  max-height: 0;
  padding: 0 28px 0 24px;
  overflow: hidden;
  background-color: rgb(var(--primary-dark));
  opacity: 0;
  -webkit-transition: max-height .3s,opacity .3s;
  -o-transition: max-height .3s,opacity .3s;
  transition: max-height .3s,opacity .3s;
}

.header-menu__navigation--open {
  max-height: 300vh;
  opacity: 1;
}

.no-seanses {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: auto;
  padding: 84px 0 111px;
}

.no-seanses__title {
  font-size: 1.25rem;
  font-weight: 700;
  text-align: center;
}

.contacts-info{
  display: flex;
  color: #fff;
  justify-content: space-around;
  width: 35%;
  align-items: center;
}

.contacts-info a{
  text-decoration: none;
  color: #fff;
}

.contacts-info .address .city{
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
}

.contacts-info .address-txt{
  font-size: .8125rem;
  font-weight: 300;
  line-height: 0;
  opacity: .6;
  -webkit-font-smoothing: antialiased;
}

.contacts-info a:hover{
  color: #fff;
}

.contacts-info .phone .phone-num{
  font-size: 1rem;
  font-weight: 400;
  line-height: 22px;
}

.se-image-container img {
  max-width: 100%;
  height: auto!important;
  height: initial!important;
}

.sm{
  display: flex;
  color: #fff;
  justify-content: flex-end;
  width: 50%;
  align-items: center;
}

.sm .social-icon-link{
  color: #fff;
  margin-right: 20px;
}

.sm .ticket-btn{
  height: 32px;
  padding: 0 16px;
  border-radius: 40px;
  font-size: .875rem;
  min-width: 140px;
  background-color: #e76d0a;
  color: #000;
  border: none;
  font-weight: 400;
}

.header-logo img{
  cursor: pointer;
  height: 62px;
  width: 165px;
}

.header-menu__icon--active::before {
  bottom: 0;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  transition: bottom .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s .3s cubic-bezier(.23,1,.32,1);
  -webkit-transition: bottom .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s .3s cubic-bezier(.23,1,.32,1);
  -o-transition: bottom .3s cubic-bezier(.23,1,.32,1),transform .3s .3s cubic-bezier(.23,1,.32,1);
  transition: bottom .3s cubic-bezier(.23,1,.32,1),transform .3s .3s cubic-bezier(.23,1,.32,1);
  transition: bottom .3s cubic-bezier(.23,1,.32,1),transform .3s .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s .3s cubic-bezier(.23,1,.32,1);
}

.header-menu__icon::before {
  bottom: 5px;
  left: 0;
  background: rgb(var(--accent));
  transition: bottom .3s .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s cubic-bezier(.23,1,.32,1);
  -webkit-transition: bottom .3s .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s cubic-bezier(.23,1,.32,1);
  -o-transition: bottom .3s .3s cubic-bezier(.23,1,.32,1),transform .3s cubic-bezier(.23,1,.32,1);
  transition: bottom .3s .3s cubic-bezier(.23,1,.32,1),transform .3s cubic-bezier(.23,1,.32,1);
  transition: bottom .3s .3s cubic-bezier(.23,1,.32,1),transform .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s cubic-bezier(.23,1,.32,1);
}

.header-menu__icon::before, .header-menu__icon::after {
  content: "";
  position: absolute;
  width: 18px;
  height: 2px;
}

.header-menu__icon--active::after {
  top: 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  transition: top .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s .3s cubic-bezier(.23,1,.32,1);
  -webkit-transition: top .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s .3s cubic-bezier(.23,1,.32,1);
  -o-transition: top .3s cubic-bezier(.23,1,.32,1),transform .3s .3s cubic-bezier(.23,1,.32,1);
  transition: top .3s cubic-bezier(.23,1,.32,1),transform .3s .3s cubic-bezier(.23,1,.32,1);
  transition: top .3s cubic-bezier(.23,1,.32,1),transform .3s .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s .3s cubic-bezier(.23,1,.32,1);
}

.header-menu__icon::after {
  top: 5px;
  left: 0;
  background: rgb(var(--accent));
  transition: top .3s .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s cubic-bezier(.23,1,.32,1);
  -webkit-transition: top .3s .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s cubic-bezier(.23,1,.32,1);
  -o-transition: top .3s .3s cubic-bezier(.23,1,.32,1),transform .3s cubic-bezier(.23,1,.32,1);
  transition: top .3s .3s cubic-bezier(.23,1,.32,1),transform .3s cubic-bezier(.23,1,.32,1);
  transition: top .3s .3s cubic-bezier(.23,1,.32,1),transform .3s cubic-bezier(.23,1,.32,1),-webkit-transform .3s cubic-bezier(.23,1,.32,1);
}

.icon-white {
  fill: #fff;
}

.menu {
  font-size: 0.875rem;
  color: #fff;
  margin-left: 10%;
  margin-top: 15px;
}

.menu.active {
  background: #fff;
  left: -40px;
  opacity: 1;
  transition: all 0.5s ease;
  z-index: 1;
  height: 49px;
}

.menu-container{
  background-color: transparent !important;
  position: relative;
  display: flex;
  margin-right: 24px;
  overflow: hidden;
}

.main-container {
  font-size: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.releases-list {
  margin: 20px 0 0;
}

.releases-soon {
  padding-top: 28px;
}

.releases-list--primary {
  padding-top: 28px;
  margin-top: 0;
  background-color: #10222d;
}


.release-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.release-left {
  position: relative;
  width: 263px;
  min-width: 263px;
  margin: 0 30px 0 0;
}

.release-right {
  min-width: calc(100% - 293px);
  max-width: calc(100% - 293px);
}

.release-poster {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  height: 382px;
  overflow: hidden;
  cursor: pointer;
}

.release-poster__img-wrapper {
  width: 100%;
  height: 100%;
  cursor: default;
}

.release__genre {
  font-size: .9375rem;
  color: #666;
}

.release__title {
  font-weight: 700;
  font-size: 2.25rem;
  margin: 6px 0 12px;
  color: #000;
}

.release-poster--mobile {
  display: none;
}

.gradient_3 {
  background: #e7846c;
  background: -webkit-linear-gradient(315deg,#e7846c 0%,#6f919d 100%);
  background: -o-linear-gradient(315deg,#e7846c 0%,#6f919d 100%);
  background: linear-gradient(135deg,#e7846c 0%,#6f919d 100%);
}

.calendar-menu {
  position: relative;
  z-index: 120;
  width: 100%;
  overflow: hidden;
}

.release-schedule-wrap .calendar-menu {
  -webkit-border-radius: 25px;
  border-radius: 25px;
}

.calendar--release-page {
  padding: 8px 12px;
  margin: -1px 0 0;
}

.calendar-mobile__date {
  font-size: 1.125rem;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #000;
  cursor: pointer;
  border: 0;
  background-color: transparent;
}

.calendar-mobile__date--holiday {
  color: #d2000d;
}

.calendar-mobile__date--day-week {
  font-size: .75rem;
  margin-bottom: 4px;
  text-transform: uppercase;
}

.calendar-mobile__date--active .calendar-mobile__date--day-month,
.calendar-mobile__date:hover .calendar-mobile__date--day-month {
  background-color: rgb(241, 175, 25);
  color: #000;
}

svg:not(:root) {
  overflow: hidden;
}

.release-container .container {
  padding: 0;
}

.release-schedule {
  margin: 30px 0 10px;
}

.release-schedule__list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 68%;
}

.release-poster__img {
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 100%;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform .3s;
  -webkit-transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s,-webkit-transform .3s;
}

.releases-list__title {
  font-size: 2rem;
  margin-top: 0;
}

.release-container .release__info {
  padding: 26px 40px 26px 30px;
  background-color: #f6f5f3;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: block;
}

.release__info-item {
  font-size: .9375rem;
  line-height: 21px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.release__info-item__name {
  width: 25%;
  color: #666;
}

.release__info-item__description {
  width: 75%;
}

.release__info-item:not(:first-child) {
  margin-top: 16px;
}

.release__text {
  font-size: .9375rem;
  line-height: 21px;
  margin: 32px 0 0;
  color: #000;
}

.g-desktop-hide {
  display: none;
}

.release__age {
  font-size: .9375rem;
  line-height: 30px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 0 8px 0 10px;
  background-color: rgb(102, 2, 5);
  color: #fff;
}

.release-rating {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
}

.releases-item {
  display: inline-block;
  overflow: visible;
  color: #000;
  text-decoration: none!important;
  cursor: pointer;
  margin: 0 2.33918128% 30px 0;
  width: 23.2459677419%;
}

.releases-item:nth-child(4n+4) {
  margin-right: 0;
}

.gradient_1 {
  background: #8e8485;
  background: -webkit-linear-gradient(315deg,#8e8485 0%,#856070 100%);
  background: -o-linear-gradient(315deg,#8e8485 0%,#856070 100%);
  background: linear-gradient(135deg,#8e8485 0%,#856070 100%);
}

.releases-item:hover .releases-item__poster-img {
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}

.release__badge {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: absolute;
  top: 8px;
  right: 8px;
}

.release__badge>div {
  margin-bottom: 8px;
}

.release__premiere {
  background-color: rgb(var(--accent));
  color: rgb(var(--accent-plus));
  padding: 0 8px;
  font-weight: 700;
  font-size: .875rem;
  line-height: 30px;
  text-transform: uppercase;
}

.releases-item__poster {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: height .3s,width .3s;
  -o-transition: height .3s,width .3s;
  transition: height .3s,width .3s;
  max-height: 384px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.releases-item__poster-height {
  margin-top: 145%;
}

.releases-item__poster-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform .3s;
  -webkit-transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s,-webkit-transform .3s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  object-fit: cover;
}

.releases-item__age {
  font-size: .9375rem;
  line-height: 30px;
  position: absolute;
  bottom: 8px;
  left: 8px;
  padding: 0 8px 0 10px;
  background-color: rgb(102, 2, 5);
  color: #fff;
}

.releases-item__badge {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  position: absolute;
  top: 8px;
  right: 8px;
}

.releases-item__premiere {
  background-color: rgb(var(--accent));
  color: rgb(var(--accent-plus));
  padding: 0 8px;
  font-weight: 400;
  font-size: .875rem;
  line-height: 24px;
  text-transform: uppercase;
}

.releases-item-description__title {
  font-weight: 500;
  font-size: 1.5rem;
  margin: 10px 0 0;
  cursor: pointer;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  transition: color .3s;
}

.releases-item-description__title:hover {
  color: #fc0d1b;
}

.releases-item-description__badge {
  font-size: .875rem;
  margin: 8px 0 4px;
  color: #666;
}

.releases-item-description__badge span:not(:last-child) {
  margin-right: 4px;
}

.releases-item-description__badge span {
  display: inline-block;
  padding: 0 6px;
  line-height: 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #f2f2f5;
  color: #727276;
  font-size: .75rem;
  font-weight: 300;
  margin-bottom: 4px;
}

.releases-item-schedule {
  font-size: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 16px 0 0;
}

.seance-item {
  font-size: .9375rem;
  line-height: 20px;
  position: relative;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
  text-align: center;
  margin: 0 8px 8px 0;
  display: inline-block;
  text-decoration: none;
}

button.seance-item {
  height: 40px;
  padding: 0 12px;
  background-color: rgb(241, 175, 25);
  color: rgb(0,0,0);
  border-style: none;
}

button.seance-item:hover {
  background-color: rgba(241, 175, 25,.8);
}

.time-tooltip {
  padding-left: 12px;
  padding-right: 12px;
}

.seance-item--space {
  margin: 0 4px;
}

.seance-item__vip {
  background-color: #fff;
  padding-right: 26px;
  width: 26px;
  -webkit-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
  border-left: 1px solid #e76d0a;
  background-image: url('/public/image/vip.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-background-size: 55% 55%;
  background-size: 55%;
}

.widget-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: transparent!important;
  outline: none;
}

.releases-list .seance-item__container,
.release-container .seance-item__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  border: 1px solid #e76d0a;
  min-width: 64px;
  background-color: #e76d0a;
  -webkit-transition: color .3s,background-color .3s;
  -o-transition: color .3s,background-color .3s;
  transition: color .3s,background-color .3s;
  outline: none;
}

.seance-item__item {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
}

.releases-list .seance-item__time,
.release-container .seance-item__time {
  padding: 4px 12px;
  line-height: 22px;
  font-size: 1rem;
  color: rgb(0,0,0);
  font-weight: 500;
}

.seance-item__wrapper {
  position: relative;
}

.releases-list .seance-item__description,
.release-container .seance-item__description {
  background-color: #fff;
  line-height: 13px;
  font-size: .6875rem;
  color: #4c4c4f;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
  padding: 3px 4px 2px 4px;
}

.seance-item__description--with-vip {
  -webkit-border-radius: 0 0 0 4px;
  border-radius: 0 0 0 4px;
}

.releases-list .seance-item__hall,
.releases-container .seance-item__hall {
  font-size: .625rem;
  line-height: 12px;
  color: #4c4c4f;
  margin-top: 3px;
}

.menu__item--active {
  cursor: default;
  border-bottom: 4px solid rgb(241, 175, 25);
}

.menu__item {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 24px;
  height: 48px;
  white-space: nowrap;
  position: relative;
  color: currentcolor;
  text-align: center;
  cursor: pointer;
  padding: 13px 8px 14px;
  text-decoration: none;
  transition: color 0.3s ease 0s;
}

.menu__item--active::before {
  visibility: visible;
  transition: none 0s ease 0s;
}

.menu__item::before {
  content: "";
  position: absolute;
  top: 44px;
  left: 0px;
  width: 100%;
  height: 4px;
  z-index: 121;
  visibility: hidden;
  background-color: rgb(241, 175, 25);
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out 0s, -webkit-transform 0.3s ease-in-out 0s;
}

.menu__item + .menu__item {
  margin: 0px 0px 0px 12px;
}

.menu__item:hover {
  color: currentcolor;
}

.menu.active .menu__item{
  color: #010E21;
}

.feedback {
  margin-right: 42px;
  font-size: 1rem;
  line-height: 21px;
  font-weight: 300;
}

.feedback__item {
  height: 32px;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(241, 175, 25);
  font-size: 0.875rem;
  min-width: 140px;
  color: rgb(0, 0, 0);
  padding: 0px 16px;
  margin: 8px 0px 8px 16px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 40px;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  list-style: none;
}

.nav-links {
  text-decoration: none;
  color: #333333;
}

.nav-links:hover {
  display: unset;
  border-bottom: 4px solid #0b51f4;
  transition: all 0.2s ease-out;
}

.nav-links-sign-in{
  color: #333333;
  text-decoration: none;
  border: 1px solid #4c4c4c;
  border-radius: 50px;
  padding: 14px 30px;
}

.nav-links-sign-in:hover{
  color: #ffffff;
  background-color: #0b51f4;
  border-color: #0b51f4;
}

.menu-icon {
  display: none;
}

.lang {
  position: absolute;
  right: 75px;
  top: 30px;
}

.lang button{
  border: none;
  background: transparent;
  font-weight: 300;
  outline: none;
  font-size: 16px;
  margin-right: 10px;
}

.lang .active {
  color: #0b51f4;
}

/* Popup style */
.popup-container {
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.popup {
  z-index: 1;
  max-width: 80%;
}

.popup__content {
  max-height: 740px;
  width: 620px;
  color: rgb(38, 38, 38);
  background: rgb(255, 255, 255);
  border-radius: 8px;
  padding: 0px 12px;
}

.popup__content__body {
  padding: 12px 24px;
}

.popup__content__header {
  position: relative;
  font-weight: 500;
  line-height: 28px;
  font-size: 1.25rem;
  text-align: center;
  border-bottom: 1px solid rgb(227, 233, 235);
  padding: 20px;
}

.popup__exit {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.popup__exit:hover {
  transform: rotate(180deg);
}

.email-popup {
  padding: 32px 20px;
}

.email-popup__header {
  position: relative;
  width: 100%;
  font-size: 2rem;
  font-weight: 700;
}

.email-popup__header__text {
  width: 80%;
}

.email-popup__exit {
  position: absolute;
  height: 30px;
  width: 30px;
  top: 6px;
  right: 0px;
  cursor: pointer;
  transition: opacity 0.3s ease 0s, transform 0.3s ease 0s, -webkit-transform 0.3s ease 0s;
}

.email-popup__form {
  display: flex;
  margin-top: 25px;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

.email-popup__input-group {
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 0%;
}

.email-popup__input-error > div, .email-popup__input-error input {
  border-color: rgb(252, 13, 27) !important;
}

.email-popup__input-group__badge {
  width: 42px;
  height: 42px;
  border-right: none;
  border-radius: 8px 0px 0px 8px;
  background: url(/public/image/mail.svg) center center no-repeat;
  transition: border-color 0.3s ease 0s;
}

.email-popup__form .input-container {
  -webkit-box-flex: 4;
  margin-right: 16px;
  margin-top: 0px !important;
  flex: 4 1 0%;
}

.email-popup__exit:hover {
  transform: rotate(180deg);
}

.input-container:not(:first-child) {
  margin-top: 5px;
}

.input--has-error {
  color: red;
  border-color: red;
}

.input {
  width: 100%;
  font-family: Roboto;
  line-height: 20px;
  font-size: 0.875rem;
  color: rgb(38, 38, 38);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(201, 201, 201);
  border-image: initial;
  padding: 10px 15px;
  transition: border-color 0.3s ease 0s;
  outline: none;
}

.email-popup__form .input-container .input__error {
  position: relative;
  left: -2.5rem;
}

.input__error {
  font-size: 0.75rem;
  margin-top: 5px;
  color: red;
}

.email-popup__button-container {
  display: flex;
}

.email-popup__form button {
  height: 42px;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  color: rgb(0,0,0);
  background-color: rgb(241,175,25);
  font-size: 1rem;
  min-width: 175px;
  padding: 12px 16px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 4px;
}

.email-popup__view-button-disabled {
  opacity: 0.6;
  cursor: unset !important;
}

.view-button {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  margin-right: 8px;
  background-color: rgb(255, 255, 255);
  font-weight: 500;
  font-size: 1rem;
  cursor: pointer;
  padding: 8px 12px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  border-radius: 4px;
}

.icon {
  display: inline-block;
  vertical-align: top;
  transition: background 0.3s ease 0s;
}

.icon-black {
  fill: rgb(0, 0, 0);
}

.feedback__description {
  line-height: 20px;
  font-size: 0.875rem;
  color: rgb(97, 97, 97);
  max-width: 576px;
  margin-bottom: 10px;
}

.feedback__box-form__label {
  line-height: 20px;
  font-size: 0.875rem;
  color: rgb(38, 38, 38);
  margin-bottom: 3px;
}

.feedback__box-form:not(:last-child) {
  margin-bottom: 16px;
}

.input-container:not(:first-child) {
  margin-top: 5px;
}

.popup__content__bottom {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 0px 24px 24px;
}

.button {
  font-size: 1.125rem;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 40px;
  color: rgb(0, 0, 0);
  cursor: pointer;
  border-radius: 3px;
  padding: 12px 42px;
  border-width: 1px;
  border-style: solid;
  border-color: initial;
  border-image: initial;
}

.g-mobile-hide {
  display: block;
}

.popup__content__bottom button {
  height: initial;
  padding: 12px 24px;
}

.button--primary {
  color: rgb(255, 255, 255);
  background: rgb(21, 125, 241);
  border-color: rgb(21, 125, 241);
}

.textarea {
  width: 100%;
  min-height: 120px;
  resize: none;
  line-height: 20px;
  font-size: 0.875rem;
  color: rgb(38, 38, 38);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(201, 201, 201);
  border-image: initial;
  padding: 10px 15px;
  transition: border-color 0.3s ease 0s;
  outline: none;
}

.footer-close {
  position: fixed;
  right: 0;
  bottom: 90px;
  width: 15px;
  background-color: #777;
  z-index: 99999;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.button--secondary {
  background: rgb(247, 249, 250);
  border-color: rgb(214, 214, 214);
}

.footer-container {
  background-color: #10222d;
  font-weight: 300;
  color: #fff;
  padding-bottom: 20px;
}

.footer-container a,
.footer-container a:hover{
  color: #fff;
  text-decoration: none;
}

.footer-container .footer-top{
  display: flex;
  background-color: #21475e;
  padding: 25px 40px;
  justify-content: space-between;
}

.footer-container .footer-top .footer-logo{
  height: 62px;
  width: 165px;
  background-size: auto 100%;
}

.footer-container .footer-top .mobile-apps img{
  border-radius: 5px;
  margin-right: 10px;
}

.footer-container .footer{
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-title{
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 22px;
}

a.footer_links-nav{
  color: #e76d0a;
  text-decoration: none;
  line-height: 22px;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  transition: color .3s;
  position: relative;
}

a.footer_links-nav:hover {
  text-decoration: underline;
  color: #e76d0a;
  transition: 0.3s ease-out;
}

.footer-container p {
  color: #fff;
  font-size: .875rem;
}

.footer-link-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.footer-link-items {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 50px 20px 50px;
  box-sizing: border-box;
}

.payments img{
  width: 38px;
  height: 24px;
}

.payments img:not(:last-child) {
  margin-right: 8px;
}

.social-icon-link {
  font-size: 24px;
}

.social-icons {
  display: flex;
  justify-content: space-between;
  width: 40%;
}

.footer-rights{
  margin-left: 50px;
}

.footer-rights p{
  line-height: 2px;
}

.footer-notice p{
  font-size: 13px;
  margin-left: 50px;
  line-height: 2px;
}

.notfound-container{
  padding: 50px 0;
}

.notfound-container{
  text-align: center;
}

.notfound-container h1{
  font-size: 5rem;
  color: #04339F;
}

.notfound-container p {
  margin: 50px auto;
}

.notfound-container a {
  color: #353a3d;
  text-decoration: none;
}

.notfound-container .box404{
  text-align: center;
}

.notfound-container .err {
  color: #04339F;
  font-size: 11rem;
  display: inline-block;
}

.notfound-container .far {
  font-size: 8.5rem;
  color: #04339F;
}

.notfound-container .err2 {
  color: #04339F;
  font-size: 11rem;
  display: inline-block;
}

.cinema-info-item__contacts {
  padding: 12px 48px;
}

.cinema-info-item__cinema-name {
  font-size: 1.5rem;
  line-height: 29px;
  margin: 0px;
}

.cinema-info-item__map {
  margin-top: 24px;
}

.cinema-info-item__contacts-wrapper {
  display: flex;
  margin-top: 24px;
}

.cinema-info-item__contacts-field {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  color: rgb(0, 0, 0);
  flex: 1 1 0%;
}

.cinema-info-item__contacts-link {
  color: black;
}

.cinema-info-item__subtitle {
  font-size: 1rem;
  font-weight: 500;
  line-height: 24px;
  margin: 0px 0px 10px;
}

.cinema-info-item__contacts-phone {
  margin-bottom: 8px;
}

.cinema-info-item__contacts-field span {
  display: inline-block;
  font-size: 0.875rem;
  line-height: 20px;
}

.cinema-info-item__socials-wrapper {
  margin-top: 24px;
}

.cinema-info-item__social-links .social-icon-link svg{
  color: #320203;
  margin-right: 30px;
}

.page, .main {
  min-height: calc(100vh - 140px);
  margin: 0 0 -194px 0;
  padding: 0 0 194px;
}

.cinema-container_current {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #f2f2f5;
  overflow: hidden;
}

.cinema-container_current-cinema {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 12px 48px;
  padding: 24px;
  min-height: 94px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  font-size: .875rem;
  line-height: 20px;
  width: 100%;
  -webkit-box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 2%), 0 6.7px 5.3px rgb(0 0 0 / 3%), 0 12.5px 10px rgb(0 0 0 / 4%), 0 22.3px 17.9px rgb(0 0 0 / 4%), 0 41.8px 33.4px rgb(0 0 0 / 5%), 0 100px 80px rgb(0 0 0 / 7%);
  box-shadow: 0 2.8px 2.2px rgb(0 0 0 / 2%), 0 6.7px 5.3px rgb(0 0 0 / 3%), 0 12.5px 10px rgb(0 0 0 / 4%), 0 22.3px 17.9px rgb(0 0 0 / 4%), 0 41.8px 33.4px rgb(0 0 0 / 5%), 0 100px 80px rgb(0 0 0 / 7%);
}

.cinema-container_current-cinema__title {
  font-size: 1.313rem;
  font-weight: 600;
  line-height: normal;
}

.city-cinemas__info-sections-switcher {
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #f9fafb;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
}

.cinema {
  margin: 25px 0;
  padding: 0 42px;
}

.cinema-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.cinema-info {
  width: 100%;
  line-height: 1.5;
  z-index: 2;
}

.cinema__title {
  font-weight: 700;
  font-size: 2rem;
  line-height: normal;
  line-height: initial;
  margin: 0 0 20px;
  color: #000;
}

.cinema-about-info p{
  font-size: 13px;
}

.city-cinemas__info-section-link {
  display: inline-block;
  padding: 6px 16px;
  color: #000;
  border: 1px solid #d9d9df;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  text-decoration: none;
  z-index: 2;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.city-cinemas__info-section-link--active {
  background-color: #e76d0a;
}

.screeningDates-container{
  padding: 12px 30px;
  background-color: #f2f2f5;
}

.release-container .screeningDates-container{
  padding: 3px 0;
  background-color: #f2f2f5;
}

.dateBtn{
  background-color: #fff;
  font-size: .875rem;
  border: 1px solid #e7e7ec;
  -webkit-border-radius: 16px;
  border-radius: 16px;
  padding: 5px 15px;
  white-space: nowrap;
  cursor: pointer;
  line-height: 20px;
  -webkit-transition: background-color .3s,border-color .3s,-webkit-border-radius .3s;
  transition: background-color .3s,border-color .3s,-webkit-border-radius .3s;
  -o-transition: background-color .3s,border-radius .3s,border-color .3s;
  transition: background-color .3s,border-radius .3s,border-color .3s;
  transition: background-color .3s,border-radius .3s,border-color .3s,-webkit-border-radius .3s;
  color: #000;
  text-decoration: none;
  margin-left: 6px;
}

.dateBtn:hover{
  color: #000;
  text-decoration: none;
}

.dateBtn--active{
  background-color: #e76d0a;
}

.daily-link{
  text-decoration: none;
  color: #000;
}

.daily-seance-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 40px 15px 40px;
  -webkit-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;
  outline: none;
  cursor: pointer;
}

.daily-seance-item:not(:last-child) {
  border-bottom: 1px solid #e6e6e6;
}

.daily-seance-item__seance-time {
  background-color: #e76d0a;
  color: #000;
  width: 64px;
  height: 32px;
  padding: 0 12px;
  line-height: 32px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
}

.daily-seance-item__seance-info {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 16px;
}

.daily-seance-item__seance-title {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 25px;
}

.daily-seance-item__genres-age-info {
  margin-top: 4px;
}

.daily-seance-item__genres-age-info span:not(:last-child) {
  margin-right: 4px;
}

.daily-seance-item__genres-age-info span {
  margin-bottom: 4px;
  display: inline-block;
  padding: 0 6px;
  line-height: 20px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  background-color: #f2f2f5;
  color: #727276;
  font-size: .75rem;
  font-weight: 300;
  text-transform: uppercase;
}

.daily-seance-item__other-info span:not(:last-child) {
  margin-right: 8px;
}

.daily-seance-item__other-info span {
  color: #727276;
  font-size: .75rem;
  font-weight: 300;
}

.daily-seance-item__other-info span:not(:last-child)::after {
  content: '\2022';
  margin-left: 8px;
}

.breadcrumbs {
  font-size: .9375rem;
  line-height: 48px;
  background-color: #f2f2f5;
  border-width: 1px 0;
  border-color: #e3e9eb;
  border-style: solid;
}
.privacy {
  padding: 20px;
}
.breadcrumbs-container {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadcrumbs .container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 48px;
}

.breadcrumbs__link {
  color: rgb(50, 2, 3);
  text-decoration: none;
  -webkit-transition: color .3s;
  -o-transition: color .3s;
  transition: color .3s;
  z-index: 2;
}

.breadcrumbs__divider {
  margin: 0 10px;
  color: #666;
}

.breadcrumbs__page {
  color: #666;
}

.promo-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 25px 40px 0 40px;
}

.promo-info {
  width: 100%;
}

.promo__title {
  font-weight: 700;
  font-size: 2rem;
  margin: 0 0 16px;
  color: #000;
}

.promo__description {
  max-width: 654px;
}

.promo__description__img {
  max-width: 100%;
  margin-top: 28px;
}

.promo__description__text {
  padding: 10px 0;
  font-size: 1rem;
  color: #414141;
  line-height: 28px;
}

.promo-items {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.promo-item {
  width: 31.090487239%;
  margin: 0 3.364880273% 10px 0;
  color: #000;
  text-decoration: none!important;
  cursor: pointer;
  height: 332px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 2;
}

.promo-item__poster {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  -webkit-transition: height .3s,width .3s;
  -o-transition: height .3s,width .3s;
  transition: height .3s,width .3s;
  height: 176px;
  -webkit-border-radius: 8px 8px 0 0;
  border-radius: 8px 8px 0 0;
}

.promo-item__poster-height {
  margin-top: 145.247148289%;
}

.promo-item__poster-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center center;
  -webkit-background-size: cover;
  background-size: cover;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  transition: -webkit-transform .3s;
  -webkit-transition: -webkit-transform .3s;
  -o-transition: transform .3s;
  transition: transform .3s;
  transition: transform .3s,-webkit-transform .3s;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -o-object-fit: cover;
  object-fit: cover;
}

.promo-item__info {
  border: 1px solid #e6e6e6;
  border-top: none;
  -webkit-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
  padding-bottom: 24px;
  padding-top: 16px;
  height: 156px;
}

.promo-item-description {
  padding: 0 16px;
}

.promo-item-description__title {
  font-size: 1.5rem;
  font-weight: 700;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 56px;
}

.promo-item-description__period {
  font-size: .875rem;
  margin-top: 8px;
  color: #262626;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 20px;
  height: 60px;
}

.soon-container{
  padding-top: 25px;
}

.soon-container .releases-item_soon:nth-child(4n+4) {
  margin-right: 2.63157%;
}

.releases-soon--title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: baseline;
  -webkit-align-items: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.releases-soon--title__container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.releases-soon--title__link {
  font-size: .875rem;
  text-decoration: none;
  color: #000;
  margin-right: 5px;
}

.releases-container {
  font-size: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.gradient_4 {
  background: #6f919d;
  background: -webkit-linear-gradient(315deg,#6f919d 0%,#e7846c 100%);
  background: -o-linear-gradient(315deg,#6f919d 0%,#e7846c 100%);
  background: linear-gradient(135deg,#6f919d 0%,#e7846c 100%);
}

.gradient_6 {
  background: #6f919d;
  background: -webkit-linear-gradient(315deg,#6f919d 0%,#856070 100%);
  background: -o-linear-gradient(315deg,#6f919d 0%,#856070 100%);
  background: linear-gradient(135deg,#6f919d 0%,#856070 100%);
}

.releases-item__date-wrapper {
  font-size: .875rem;
  margin: 14px 0 0;
  color: #666;
}

.releases-item-description__title_small {
  font-size: 1.125rem;
  line-height: 24px;
  word-wrap: break-word;
}

.releases-item__poster_soon {
  max-height: 237px;
}

.releases-item_soon {
  width: 14%;
  margin: 0 2.63157% 30px 0;
}

.login-container{
  padding: 40px;
  display: flex;
}

.login-container .feedback__item{
  border: none;
}

.login-container .feedback__item:hover {
  color: #fff;
  background-color: #660205;
}

.feedback__item a{
  text-decoration: none;
  color: #000;
}

.login-container .btn_promo{
  background-color: rgb(241, 175, 25);
  padding: 7px 16px;
  color: #000;
  font-size: 14px;
  border-radius: 40px;
  text-decoration: none;
  margin-right: 10px;
  border: none;
  outline: none;
  cursor: pointer;
}

.login-container .promo_form_col_4{
  flex: 0 0 33%;
  max-width: 33%;
}

.login-container .promo_list_col_8{
  flex: 0 0 67%;
  max-width: 67%;
  margin-left: 30px;
}

.promo_list_col_8 p{
  font-weight: 300;
  font-size: 14px;
}

.promo_list_col_8 button{
  margin-left: 20px;
}

.releases-list--white {
  padding-top: 28px;
  margin-top: 0;
  background-color: #fff;
}

.news-others {
  padding: 28px 0 48px;
}

.promo-container--others {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.promo__title-others {
  font-size: 2rem;
  color: #fff;
  margin: 0;
}

.promo__title-container {
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 2;
}

.promo__title-link {
  text-decoration: none;
  font-size: .875rem;
  color: #fff;
  margin-right: 5px;
}

.icon--arrow-right {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.promo-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0 50px;
}

.promo-list-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.promo-item__others {
  width: 32%;
  margin: 0;
  background-color: #fff;
  margin-right: 30px;
}

.promo-item__others:last-child {
  margin-right: 0;
}

.promo__title-others--black {
  color: #000;
}

.promo__title-link--black {
  color: #000;
}

.promo-item-description__title-others {
  font-size: 1.25rem;
  font-weight: 500;
  height: 50px;
}

@media screen and (max-width: 1370px) {
  #root{
    background-position-y: -80px;
  }
  .navbar-logo img {
    width: 80%;
  }

  .lang {
    right: 75px;
  }

  .footer-link-items {
    margin: 0 15px 0 40px;
  }
}

@media screen and (max-width: 1199px) {
  #root {
    background-position-x: 7%;
  }

  .container {
    padding-right: 24px;
    padding-left: 24px;
}

  .lang {
    right: 30px;
  }

  .nav-menu{
    width: 55vw;
  }

  .navbar-logo {
    width: 20vw;
  }

  .footer-link-items {
    margin: 0 7px 0 7px;
}
}

@media screen and (max-width: 991px) {
  .header {
    margin-bottom: 50px;
  }

  .menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 35vh;
    position: absolute;
    top: 100px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    justify-content: flex-start;
  }

  .nav-menu.active {
    background: #fff;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item {
    height: unset;
  }

  .nav-links-sign-in{
    border: unset;
    font-weight: 300;
    letter-spacing: .64px;
    padding: 1rem;
    border-radius: unset;
    display: flex;
  }

  .nav-links-sign-in:hover{
      border: unset;
  }

  .nav-links{
    padding: 1rem;
  }

  .nav-links:hover {
    background-color: #0b51f4;
    color: #fff;
    border-bottom: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: white;
  }

  .align-items-start img{
    width: 25%;
  }

  .types .generic_content .generic_head_price .generic_head_content .head_bg {
    border-top-width: 116px;
  }

  .footer-link-wrapper .title{
    width: 100%;
    text-align: center;
  }

  .footer-logo{
    margin: auto;
  }

  .footer-link-items h5{
    margin: 30px auto;
  }

  .footer-link-items {
    margin: 0px 20px 0 20px;
  }

  .releases-item {
    width: 31.090487239%;
    margin: 0 3.364880273% 30px 0;
  }

  .releases-item__poster {
    max-height: 408px;
}

.header__social {
  display: none;
}

.releases-item {
  width: 31.090487239%;
  margin: 0 3.364880273% 30px 0;
}
  
}

@media screen and (max-width: 767px) {
  #root {
    background-position-x: 16%;
  }

  .container {
    padding-right: 18px !important;
    padding-left: 18px !important;
}

.header{
  padding: 15px 20px;
}

.menu {
  padding: 0 20px;
}

.screeningDates-container {
  padding: 12px;
  overflow-x: auto;
  display: flex;
}

.release-container{
  display: unset;
}

.release-right{
  min-width: none;
  max-width: none;
}

.release-schedule__list{
  max-width: unset;
}

.release__info-item{
  flex-direction: column;
}

.release-container .release__info {
  padding: 15px;
}

.daily-seance-item{
  padding: 15px 20px;
}

  .navbar-logo {
    width: 25vw;
  }

  .align-items-start img {
    width: 50px;
  }

  .popup__content{
    width: unset;
    padding: 0;
  }

  .popup__content__body{
    padding: 12px;
  }

  .footer-container .footer{
    padding: 0 15px;
  }

  .footer-container .footer-top{
    display: block;
    padding: 15px 30px;
  }

  .footer-link-wrapper .title {
    width: unset;
    text-align: unset;
  }

  .footer-link-wrapper .title h5{
    font-size: 17px;
  }

  .footer-link-items {
    margin: 0 0 0 20px;
  }

  .footer-rights{
    margin-left: 30px;
  }

  .footer-notice p{
    margin-left: 30px;
    line-height: unset;
  }

  .footer-logo {
    margin-top: unset;
    margin-bottom: unset;
  }

  .header__container {
    position: relative;
    z-index: 100;
    height: 70px;
    background-color: rgb(102, 2, 5);
}

.header__logo {
  height: 48px;
}

.header__logo-img {
  height: 52px;
}

.header__container .header__address, .header__container .header__phone, .header__container .header__social {
  display: none;
}

.header__social {
  visibility: visible;
  margin: 16px 0 20px;
}

.header-menu {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

  .releases-list {
    margin: 0;
}

.main-container {
  padding: 0 !important;
}

.header-menu__navigation {
  display: block;
}

  .releases-item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin: 0;
    border-bottom: 1px solid #e6e6e6;
    padding: 16px 6px 8px 20px;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
  }

  .releases-item__poster {
    -webkit-box-flex: 0;
    -webkit-flex: none;
    -ms-flex: none;
    flex: none;
    width: 110px;
    height: 170px;
    margin: 0 11px 16px 0;
    overflow: hidden;
}

.releases-list--primary {
  padding-top: 16px;
}

.releases-container{
  padding: 0 !important;
}

.promo-container--others {
  margin-bottom: 4px;
}

.promo-list {
  overflow: auto;
  padding: 0;
}

.promo-list-container {
  width: auto;
}

.promo-item__others {
  width: 264px!important;
}

.promo-item {
  height: unset;
  width: 100%;
  -webkit-transition: background-color .3s;
  -o-transition: background-color .3s;
  transition: background-color .3s;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
}

.promo-item__poster {
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  width: 60px;
  width: 100%;
  margin: 0;
  overflow: hidden;
}

.promo-item__others .promo-item__poster, .promo-item__others .promo-item__info {
  height: 130px;
}

.promo-item__poster-height {
  display: none;
}

.promo-item__others .promo-item__poster, .promo-item__others .promo-item__info {
  height: 130px;
}

.promo-item-description {
  font-size: .875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: baseline;
  -ms-flex-line-pack: baseline;
  align-content: baseline;
  color: #030303;
}

.promo-item-description__title {
  font-size: 1.313rem;
  margin-bottom: 0;
  font-weight: 500;
  max-height: 50px;
  width: 100%;
}

.promo-item__others .promo-item-description__period {
  height: 40px;
}

.releases-soon {
  padding-top: 16px;
}

.releases-soon__mini {
  background-color: #f8f8f8;
}

.releases-item__info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.releases-item__poster-height {
  display: none;
}

.releases-item__age {
  display: none;
  font-size: .75rem;
  line-height: 26px;
  padding: 0 8px;
}

.releases-item__badge {
  font-size: .75rem;
  line-height: 26px;
  display: none;
}

.releases-item__info {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.releases-item__date-wrapper {
  margin: 0 0 8px;
}

.releases-item-description {
  font-size: .875rem;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: baseline;
  -ms-flex-line-pack: baseline;
  align-content: baseline;
  margin-right: 6px;
  color: #030303;
}

.releases-item-description__title {
  font-weight: 500;
  font-size: 1.313rem;
  width: 100%;
  margin: 0 0 8px;
}

.releases-item-description__badge {
  margin: 0 0 10px 4px;
}

.menu--navigation {
  height: 100%;
}

.menu-container {
  border-bottom: 1px solid #e3e9eb;
  overflow-x: auto;
  margin-right: 0;
  height: 49px;
}

.cinema-container_current-cinema {
  margin: 12px 16px;
}

.contacts-info{
  display: none;
}

.sm{
  display: none;
}

.release-schedule {
  margin-left: 5px;
}

}

@media screen and (max-width: 675px) {
 
}

@media only screen and (max-width: 575px) {
  
}

@media only screen and (max-width: 495px) {
  #root{
    background-position-x: 20%;
  }
}

@media screen and (max-width: 395px) {
  #root{
    background-position-x: 22%;
  }
}

@media only screen and (max-width: 355px) {
  
}

.release-description br {
}
