.hallPlan-container #root {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%
}

.hallPlan-container .app {
    position: relative;
    width: 100%;
    max-width: 100vw;
    height: 100%;
    background-color: #fff
}

.hallPlan-container .app-page {
    display: flex;
    flex-direction: column;
    height: calc(100% - 30px);
    overflow: hidden;
    background-color: #fff
}

.hallPlan-container .app-container {
    height: 100%
}

.hallPlan-container .app-header {
    position: relative;
    z-index: 12;
    padding: 16px 0 12px;
    background-color: #fff;
    text-align: center;
    box-shadow: 0px 0px 3px rgba(0,0,0,.25)
}

.hallPlan-container .popuphall{
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    background-color: #fff;
}

.app-header__title {
    margin: 0 0 3px
}

.app-header__address {
    letter-spacing: -0.39px;
    color: #727276
}

.app-header .popup-back-icon {
    top: calc(50% - 12px)
}

.app-afisha {
    height: calc(100% - 72px);
    border-top: 1px solid #e7e7ec
}

.app-footer {
    font-size: 13px;
    line-height: 30px;
    background-color: #262626;
    color: rgba(255,255,255,.6);
    text-align: center;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    position: relative;
    z-index: 12;
}

.app-footer__phone {
    white-space: nowrap;
    padding-right: 20px
}

.app-footer__link {
    color: rgba(255,255,255,.6)
}

.app-footer__block {
    display: flex;
    align-items: center
}

.app-footer__copy {
    display: flex;
    align-items: center
}

.app-footer__copy:hover {
    cursor: pointer
}

.app-footer__copy--image {
    display: inline-block;
    margin-right: 2px;
    /* background: url(/82804d5f63ba96a7b8df.svg) 0 0 no-repeat; */
    height: 16px;
    width: 16px
}

.app-footer__copy--text {
    margin-right: 15px
}

.app-footer__refund {
    color: rgba(255,255,255,.6);
    margin-right: 15px;
    cursor: pointer
}

.gradient_1 {
    background: #8e8485;
    background: linear-gradient(135deg, #8e8485 0%, #856070 100%)
}

.gradient_2 {
    background: #856070;
    background: linear-gradient(135deg, #856070 0%, #8e8485 100%)
}

.gradient_3 {
    background: #e7846c;
    background: linear-gradient(135deg, #e7846c 0%, #6f919d 100%)
}

.gradient_4 {
    background: #6f919d;
    background: linear-gradient(135deg, #6f919d 0%, #e7846c 100%)
}

.gradient_5 {
    background: #856070;
    background: linear-gradient(135deg, #856070 0%, #6f919d 100%)
}

.gradient_6 {
    background: #6f919d;
    background: linear-gradient(135deg, #6f919d 0%, #856070 100%)
}

.gradient_7 {
    background: #e7846c;
    background: linear-gradient(135deg, #e7846c 0%, #856070 100%)
}

.gradient_8 {
    background: #856070;
    background: linear-gradient(135deg, #856070 0%, #e7846c 100%)
}

.bar_category-list__container {
    display: flex;
    padding: 8px 16px;
    white-space: nowrap;
    overflow: hidden;
    font-size: 15px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

@media only screen and (max-width: 767px) {
    .bar_category-list__container {
        overflow:auto
    }
}

.bar_category-list__container>div {
    white-space: nowrap;
    min-width: unset;
    padding: 6px 20px;
    line-height: 20px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 16px
}

.bar_category-list__container>div:not(:last-child) {
    margin-right: 4px
}

.bar_category-item {
    color: #262626;
    background-color: #f2f2f5
}

.bar_category-item-chosen {
    color: #fff;
    background-color: #3788d0
}

.bar_category-scroll {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 50px;
    height: 50px;
    top: 0;
    left: 0;
    transform: rotate(180deg);
    background: linear-gradient(270deg, rgb(255, 255, 255) 38%, rgba(255, 255, 255, 0) 100%);
    opacity: .7;
    cursor: pointer
}

.bar_category-scroll__right {
    transform: none;
    left: unset;
    right: 0
}

.bar_category-scroll>svg {
    margin: auto
}

.bar_banner__container {
    position: relative;
    padding-top: 4px
}

.bar_banner-item>img {
    border-radius: 8px;
    width: 95%
}

.bar_banner__control {
    position: absolute;
    top: 0;
    width: 20%;
    height: 100%;
    right: 0;
    background: #fff;
    background: linear-gradient(-90deg, rgb(255, 255, 255) 0%, rgba(255, 255, 255, 0) 100%);
    opacity: 0;
    transition: opacity .1s ease-in-out;
    cursor: pointer
}

.bar_banner__control>svg {
    right: 0;
    position: absolute;
    top: 0;
    margin: auto;
    bottom: 0;
    opacity: .7
}

.bar_banner__control-left {
    transform: rotate(180deg);
    left: 0;
    right: unset
}

.bar_banner__control:hover {
    opacity: .2
}

.button {
    font-size: 15px;
    line-height: 20px;
    display: flex-inline;
    justify-content: center;
    align-items: center;
    height: 48px;
    border-radius: 4px;
    padding: 10px 30px;
    color: #262626;
    cursor: pointer
}

.button__sidebar {
    width: 100%;
    padding: 10px 20px
}

.button--primary {
    background-color: #0a7aff;
    color: #fff
}

.button--primary:hover:not(.disabled) {
    color: #fff;
    background-color: color(#3788D0 a(80%))
}

.button--primary:active:not(.disabled),.button--primary.active:not(.disabled) {
    color: #fff;
    background-color: color(#3788D0 a(90%))
}

.button--primary:active:not(.disabled):focus {
    color: #fff;
    background-color: color(#3788D0 a(90%))
}

.button--primary.disabled {
    color: #b0b0b6;
    background-color: #f2f2f5
}

.button--secondary {
    background-color: #e7e7ec;
    color: #262626
}

.button--secondary:hover:not(.disabled) {
    color: #262626;
    background-color: #d9d9df
}

.button--secondary:active:not(.disabled),.button--secondary.active:not(.disabled) {
    color: #262626;
    background-color: #c7c7cd
}

.button--secondary:active:not(.disabled):focus {
    color: #262626;
    background-color: #c7c7cd
}

.button--secondary.disabled {
    color: #d9d9df;
    background-color: #f2f2f5
}

.button--success {
    background-color: #2db745;
    color: #fff
}

.button--success:hover:not(.disabled) {
    color: #fff;
    background-color: color(#2DB745 a(80%))
}

.button--success:active:not(.disabled),.button--success.active:not(.disabled) {
    color: #fff;
    background-color: color(#2DB745 a(90%))
}

.button--success:active:not(.disabled):focus {
    color: #fff;
    background-color: color(#2DB745 a(90%))
}

.button--success.disabled {
    color: #b0b0b6;
    background-color: #f2f2f5
}

.button--gold {
    padding: 10px 20px;
    background-color: #eed479;
    color: #262626
}

.button--gold:hover:not(.disabled) {
    color: #262626;
    background-color: rgba(238,212,121,.8)
}

.button--gold:active:not(.disabled),.button--gold.active:not(.disabled) {
    color: #262626;
    background-color: rgba(238,212,121,.9)
}

.button--gold:active:not(.disabled):focus {
    color: #262626;
    background-color: rgba(238,212,121,.9)
}

.button--gold.disabled {
    color: #262626;
    background-color: rgba(238,212,121,.8)
}

.button--white {
    padding: 10px 20px;
    background-color: #fff;
    color: #262626
}

.button--white:hover:not(.disabled) {
    color: #262626;
    background-color: color(rgb(255, 255, 255), 0.8)
}

.button--white:active:not(.disabled),.button--white.active:not(.disabled) {
    color: #262626;
    background-color: rgba(255,255,255,.9)
}

.button--white:active:not(.disabled):focus {
    color: #262626;
    background-color: rgba(255,255,255,.9)
}

.button--white.disabled {
    color: #262626;
    background-color: color(rgb(255, 255, 255), 0.8)
}

.button--black {
    padding: 10px 20px;
    background-color: #000;
    color: #fff
}

.button--black:hover:not(.disabled) {
    color: #fff;
    background-color: color(#000 a(80%))
}

.button--black:active:not(.disabled),.button--black.active:not(.disabled) {
    color: #fff;
    background-color: color(#000 a(90%))
}

.button--black:active:not(.disabled):focus {
    color: #fff;
    background-color: color(#000 a(90%))
}

.button--black.disabled {
    color: #fff;
    background-color: color(#000 a(80%))
}

.button--green {
    padding: 10px 20px;
    background-color: #21a038;
    color: #fff
}

.button--green:hover:not(.disabled) {
    color: #fff;
    background-color: color(#21A038 a(80%))
}

.button--green:active:not(.disabled),.button--green.active:not(.disabled) {
    color: #fff;
    background-color: color(#21A038 a(90%))
}

.button--green:active:not(.disabled):focus {
    color: #fff;
    background-color: color(#21A038 a(90%))
}

.button--green.disabled {
    color: #fff;
    background-color: color(#21A038 a(80%))
}

.button.disabled {
    cursor: not-allowed;
    pointer-events: none
}

.button_payment {
    display: flex
}

.button_payment>span {
    margin-left: 10px
}

.input::-moz-placeholder {
    color: #727276;
    font-size: 13px;
    line-height: 20px
}

.input::placeholder {
    color: #727276;
    font-size: 13px;
    line-height: 20px
}

.input:focus {
    border-color: #007aff
}

.input--has-error {
    border-color: #ff3b30;
    color: #ff3b30
}

.input--has-error:focus {
    border-color: #ff3b30;
    color: #ff3b30
}

.input--disabled {
    border: 1px solid #e7e7ec;
    background-color: #f9fafb
}

.input--correct {
    border: 1px solid #2db745
}

.input--warning {
    border: 1px solid #cf7900
}

.input-container:not(:first-child) {
    margin-top: 5px
}

.input-container:not(:last-child) {
    margin-bottom: 5px
}

.input__error {
    font-size: 12px;
    margin-top: 5px;
    color: #ff3b30
}

.input__info {
    font-size: 12px;
    margin-top: 5px;
    color: #262626
}

.input__warning {
    font-size: 12px;
    margin-top: 5px;
    color: #cf7900
}

.input__warning>span>button {
    color: #cf7900;
    text-decoration: underline
}

.input-checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    background-color: #2db745;
    color: #fff;
    position: relative;
    left: -32px;
    top: 3px;
    font-size: 16px;
    flex-shrink: 0
}

.input-content {
    display: flex;
    align-items: center
}

.order-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    background: #efeff4
}

.order-poster {
    flex: none;
    width: 286px;
    height: 417px
}

.order-poster__wr {
    position: relative;
    z-index: 12;
    width: 333px;
    border-right: 1px solid #e7e7ec;
    padding: 16px 24px 0 22px;
    background-color: #fff
}

.order-poster__wr--description {
    width: 100%;
    padding: 0;
    border: none
}

.order-poster__wr--sidebar {
    position: relative;
    z-index: 12;
    display: flex;
    background-color: #fff
}

.order-poster--mobile {
    display: none
}

.order-poster__img {
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 8px
}

.order-poster__siderbar {
    min-width: 72px;
    height: 104px;
    margin-right: 14px;
    border-radius: 4px
}

.order-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.order-info__container {
    overflow-y: auto
}

.order-info__genre {
    font-size: 14px;
    margin-left: 11px;
    color: #727276
}

.order-info__release {
    flex-shrink: 0;
    padding: 8px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px
}

.order-info__release-wrapper {
    flex: 1
}

.order-info__release-wrapper .order-subtitle {
    margin: 5px 0 0 0
}

.order-info__release-format {
    display: inline-block;
    border: 1px solid #e7e7ec;
    border-radius: 3px;
    padding: 2px 4px;
    background-color: #f9fafb
}

.order-info__release-format+.order-info__release-format {
    margin: 0 0 0 4px
}

.order-info__release-cinema,.order-info__release-hall,.order-info__release-datetime {
    line-height: 18px;
    margin-top: 6px
}

.order-info__release_subsale {
    border-bottom: none
}

.order-info__release-prop-item {
    display: inline-block;
    background: #f9fafb;
    padding: 0 4px;
    font-size: 12px;
    line-height: 16px;
    color: #727276;
    border-radius: 4px;
    text-transform: uppercase
}

.order-info__release-prop-item:not(:last-child) {
    margin-right: 4px
}

.order-total {
    line-height: 20px;
    flex: 1;
    display: block
}

.order-total--price {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px
}

.order-total__table-container {
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 10px 10px 1px 10px;
}

.order-total__table {
    width: 100%;
    border-collapse: collapse
}

.order-total__table tr:not(:last-child) td {
    border-bottom: 1px solid #e7e7ec
}

.order-total__table th {
    font-weight: normal;
    font-size: 12px;
    border-bottom: 1px solid #e7e7ec;
    padding: 0 5px;
    color: #b0b0b6;
    text-align: right;
    white-space: nowrap
}

.order-total__table th:first-child {
    padding-left: 0;
    text-align: left
}

.order-total__table th:last-child {
    padding-right: 0
}

.order-total__table td {
    font-size: 15px;
    padding: 12px 0 10px;
    text-align: right
}

.order-total__table td:first-child {
    text-align: left
}

.order-total__table-discount-field {
    color: #ff3b30
}

.order-table-description {
    padding: 12px 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 100%;
    flex-shrink: 0;
    margin-top: 8px
}

@media only screen and (max-width: 767px) {
    .order-table-description {
        padding:12px
    }
}

.order-table-description th {
    font-weight: normal;
    font-size: 14px;
    color: #727276;
    text-align: left
}

.order-table-description td {
    font-size: 20px;
    font-weight: 500
}

.order-table__ticket-type-caption {
    font-size: 12px;
    line-height: 16px;
    color: #3c3c43;
    opacity: .6
}

.order-email {
    padding: 18px 16px 12px 16px;
    background-color: #efeff4;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.order-email__subsale {
    background-color: #fff;
    height: auto
}

.order-email input {
    margin: 4px 0 0;
    flex-shrink: 0
}

.order-email-button {
    padding-top: 8px
}

.order-email__law-document-links-wrapper {
    margin-top: 8px;
    text-align: center;
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
    color: #727276
}

.order-email__law-document-link {
    color: #727276;
    text-decoration: underline;
    cursor: pointer
}

.order-phone {
    padding-top: 18px
}

.order-subtitle {
    font-size: 17px;
    margin: 8px 0;
    line-height: 22px
}

.order-bonuses-wrapper .order-bonus:not(:last-child) {
    margin-bottom: 8px
}

.order-bonus {
    padding: 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px
}

.order-bonus__header {
    display: flex;
    justify-content: space-between
}

.order-bonus__header:hover {
    cursor: pointer
}

.order-bonus__header-title {
    font-weight: normal
}

.order-bonus__toggle-icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* background: url(/37fba5cef2475809452e.svg) no-repeat center center; */
    transition: .2s ease-in
}

.order-bonus__form {
    max-height: 0;
    opacity: 0;
    pointer-events: none;
    transition: max-height .15s ease-out,opacity .2s ease-out
}

.order-bonus__input {
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(0,0,0,0);
    border-radius: 4px
}

.order-bonus__input:focus {
    outline: none;
    caret-color: #0a7aff;
    border-color: #0a7aff
}

.order-bonus__input--with-icon {
    padding-left: 44px;
    /* background: url(/956ddfd1e8b7b0702523.svg) no-repeat 12px */
}

.order-bonus__input--error {
    caret-color: #ff3b30;
    border-color: #ff3b30
}

.order-bonus__input--error:focus {
    caret-color: #ff3b30;
    border-color: #ff3b30
}

.order-bonus__input-error-message {
    font-size: 15px;
    height: 16px;
    color: #ff3b30
}

.order-bonus__button {
    width: 100%;
    margin-top: 8px;
    color: #0a7aff;
    background-color: #f9fafb;
    transition: .2s ease-in
}

.order-bonus--open .order-bonus__toggle-icon {
    transform: rotate(180deg);
    transition: .2s ease-in
}

.order-bonus--open .order-bonus__form {
    max-height: 300px;
    opacity: 1;
    pointer-events: all;
    transition: .2s ease-in
}

.order-bonus__info {
    padding: 16px;
    background-color: #f9fafb;
    border-radius: 8px
}

.order-bonus__info-header {
    display: flex;
    justify-content: space-between
}

.order-bonus__info-main {
    margin-top: 24px
}

.order-bonus__icon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* background: url(/f4d7ae187a5652d36114.svg) no-repeat center/cover */
}

.order-bonus__reset-button {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #fff;
    /* background-image: url(/509481dc64862bfb1b3c.svg); */
    background-repeat: no-repeat;
    background-position: center
}

.order-bonus__card-number {
    line-height: 20px
}

.order-bonus__description {
    color: #b0b0b6;
    line-height: 18px
}

.order-bonus__controls-section {
    display: flex;
    align-items: center;
    margin-top: 16px
}

.order-bonus__amount-info {
    flex: 1;
    transition: .2s ease-in
}

.order-bonus__amount-info:not(.order-bonus__amount-info--spending-bonuses) {
    opacity: .5
}

.order-bonus__amount-info:not(.order-bonus__amount-info--spending-bonuses) .order-bonus__change-amount-link {
    pointer-events: none
}

.order-bonus__amount-title,.order-bonus__change-amount-link,.order-bonus__no-bonuses-title {
    margin: 0;
    font-size: 15px;
    line-height: 20px
}

.order-bonus__change-amount-link {
    color: #0a7aff;
    cursor: pointer;
    transition: .2s ease-in
}

.order-bonus__change-amount-link:hover {
    opacity: .8
}

.order-bonus__toggle {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 30px;
    background: #b0b0b6;
    border-radius: 25px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    transition: .2s ease-in
}

.order-bonus__toggle::after {
    content: "";
    position: absolute;
    top: 1px;
    left: 1px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 3px 8px rgba(0,0,0,.15),0px 3px 1px rgba(0,0,0,.06);
    transition: .2s ease-in
}

.order-bonus__toggle:checked {
    background: #2db745
}

.order-bonus__toggle:checked::after {
    transform: translateX(20px)
}

.order-result__info-wrapper {
    padding: 12px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px
}

.order-result__info {
    border-bottom: 1px solid #e7e7ec
}

.order-result__total {
    margin-top: 8px
}

.order-result__info-row,.order-result__total-row {
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 8px
}

.order-result__info-row--discount span:last-child,.order-result__total-row--discount span:last-child {
    color: #ff3b30
}

.order-result__info-row--service,.order-result__total-row--service {
    display: flex;
    align-items: center;
    cursor: pointer
}

.order-result__info-row--service img,.order-result__total-row--service img {
    margin-left: 4px
}

.order-result__total-row {
    margin-bottom: 0
}

.order-result__total-row--sum span:last-child {
    font-weight: bold;
    font-size: 20px;
    line-height: 24px
}

.order-result__total-row--bonuses span:last-child {
    margin-left: auto;
    color: #2db745
}

.order-popup {
    padding: 16px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    width: 400px
}

@media only screen and (max-width: 543px) {
    .order-popup {
        width:90vw
    }
}

.order-pincode-popup {
    min-height: 185px
}

.order-pincode-popup__info-title {
    color: #727276;
    font-size: 12px;
    line-height: 16px
}

.order-pincode-popup__info-title--error {
    color: #ff3b30
}

.order-bonuses-amount-popup__input-section {
    display: flex;
    align-items: center;
    justify-content: flex-start
}

.order-bonuses-amount-popup__input {
    width: 100px;
    margin: 0 8px
}

.order-bonuses-amount-popup__button {
    width: 100%;
    margin-top: 8px;
    transition: .2s ease-in
}

.order-payment-method {
    padding: 0;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.order-payment-method_radio-item {
    display: flex;
    padding: 0 16px;
    cursor: pointer;
    transition: .15s background-color ease-in-out
}

.order-payment-method_radio-item__content {
    display: flex;
    padding: 10px;
    width: 100%
}

.order-payment-method_radio-item__content * {
    vertical-align: middle
}

.order-payment-method_radio-item__content>div {
    margin-left: 10px;
    font-size: 15px;
    line-height: 20px
}

.order-payment-method_radio-item__content:not(:last-child) {
    border-bottom: .3px solid rgba(60,60,67,.29)
}

.order-payment-method_radio-item__content>input {
    display: none
}

.order-payment-method_radio-item__checked {
    color: #0a7aff
}

.order-payment-method_radio-item__disabled {
    cursor: initial
}

.order-payment-method_radio-item__disabled>div>*:not(:last-child) {
    opacity: .18
}

.order-payment-method_radio-item__error-tooltip {
    visibility: hidden;
    background: #fdfdfd;
    position: absolute;
    right: -20px;
    bottom: 20px;
    padding: 16px;
    border-radius: 13px;
    width: 200px;
    font-size: 13px;
    line-height: 18px;
    opacity: 0;
    transition: .15s all ease-in-out
}

.order-payment-method_radio-item__error-tooltip::after {
    opacity: 0;
    content: "";
    position: absolute;
    top: 100%;
    right: 22px;
    border-width: 10px;
    border-style: solid;
    border-color: #fdfdfd rgba(0,0,0,0) rgba(0,0,0,0) rgba(0,0,0,0)
}

.order-payment-method_radio-item__marker {
    position: relative;
    margin-left: auto !important
}

.order-payment-method_radio-item__marker:hover .order-payment-method_radio-item__error-tooltip {
    opacity: 1;
    visibility: visible
}

.order-payment-method_radio-item__marker:hover .order-payment-method_radio-item__error-tooltip::after {
    opacity: 1
}

.order-payment-method_radio-item:first-child {
    border-radius: 8px 8px 0 0;
    padding-top: 6px
}

.order-payment-method_radio-item:last-child {
    padding-bottom: 6px;
    border-radius: 0 0 8px 8px
}

.order-payment-method_radio-item:not(:last-child)>div {
    border-bottom: .3px solid rgba(60,60,67,.29)
}

.order-payment-method_radio-item:hover {
    background-color: #e7e7ec
}

.order-pushkin-form {
    padding: 18px 16px 12px 16px;
    background-color: #efeff4;
    height: 100%;
    display: flex;
    flex-direction: column
}

.order-pushkin-form>div:not(:last-child) {
    margin-bottom: 16px
}

.order-pushkin-form input {
    margin: 4px 0 0;
    flex-shrink: 0
}

.order-pushkin-form_controls {
    display: flex;
    padding: 8px 16px;
    height: 64px
}

.order-pushkin-form_controls__back-button {
    display: flex;
    flex: 1
}

.order-pushkin-form_controls__confirm-button {
    display: flex;
    width: 70%;
    margin-left: 8px
}

.order-pushkin-form_controls__confirm-button>button {
    flex: 1
}

.order-service-fee-popup_container {
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 375px;
    border-radius: 8px
}

.order-service-fee-popup_container>div:not(:last-child) {
    margin-bottom: 20px
}

@media only screen and (max-width: 767px) {
    .order-service-fee-popup_container {
        position:absolute;
        width: 100%;
        bottom: 0;
        border-radius: 8px 8px 0 0
    }
}

.order-service-fee-popup_header {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.order-service-fee-popup_header>div {
    font-size: 20px
}

.order-service-fee-popup_body {
    font-size: 15px
}

.order-service-fee-popup_button {
    color: #0a7aff !important
}

.order-service-fee-popup_transition-enter {
    opacity: 0
}

.order-service-fee-popup_transition-enter-active {
    opacity: 1;
    transition: opacity 200ms
}

.order-service-fee-popup_transition-exit {
    opacity: 1
}

.order-service-fee-popup_transition-exit-active {
    opacity: 0;
    transition: opacity 200ms
}

.order-service-fee-popup_transition__mobile-enter {
    transform: translateY(100%)
}

.order-service-fee-popup_transition__mobile-enter-active {
    transform: translateY(0);
    transition: transform 200ms
}

.order-service-fee-popup_transition__mobile-exit {
    transform: translateY(0)
}

.order-service-fee-popup_transition__mobile-exit-active {
    transform: translateY(100%);
    transition: transform 200ms
}

.sbp-applist {
    display: flex;
    flex-direction: column
}

.sbp-applist_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 20px
}

.sbp-applist_wrapper {
    transform: translateY(100%);
    background-color: #fff;
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column
}

.sbp-applist_item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    font-size: 15px;
    line-height: 20px
}

.sbp-applist_item>img {
    margin-right: 8px
}

.sbp-applist_item:not(:last-child) {
    border-bottom: .3px solid;
    border-color: #3c3c43
}

.sbp-applist_popup-container {
    overflow: auto
}

.sbp-applist_popup-node {
    position: absolute;
    bottom: 0;
    max-height: 90%
}

.sbp-applist_transition-enter {
    transform: translateY(100%)
}

.sbp-applist_transition-enter-active {
    transform: translateY(0);
    transition: .3s transform ease-in-out
}

.sbp-applist_transition-enter-done {
    transform: translateY(0)
}

.sbp-applist_transition-exit {
    transform: translateY(0)
}

.sbp-applist_transition-exit-active {
    transform: translateY(100%);
    transition: .3s transform ease-in-out
}

.sbp-payment {
    display: flex;
    flex-direction: column;
    padding: 20px 28px;
    max-width: 450px;
    background-color: #efeff4
}

@media only screen and (max-width: 767px) {
    .sbp-payment {
        height:100%;
        justify-content: center
    }
}

.sbp-payment>* {
    margin: 12px 0
}

.sbp-payment_wrapper {
    width: 100%;
    background-color: #efeff4;
    display: flex;
    align-items: center;
    justify-content: center
}

@media only screen and (max-width: 767px) {
    .sbp-payment_wrapper {
        display:initial
    }
}

.sbp-payment_logo {
    max-width: 323px;
    margin-top: 4px
}

@media only screen and (max-width: 767px) {
    .sbp-payment_logo {
        margin:4px auto 12px
    }
}

.sbp-payment_instruction-text {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.24px
}

.sbp-payment_sum {
    display: flex;
    justify-content: space-between
}

@media only screen and (max-width: 543px) {
    .sbp-payment_sum {
        justify-content:space-between;
        align-items: center;
        gap: 8px
    }
}

.sbp-payment_sum__caption {
    font-size: 13px;
    line-height: 18px
}

.sbp-payment_sum__number {
    font-size: 17px;
    line-height: 22px
}

.sbp-payment_qr {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
    min-width: 300px;
    border: 1px solid #c6c6c8;
    background-color: #fff
}

@media only screen and (max-width: 767px) {
    .sbp-payment_qr {
        margin:12px auto
    }
}

.sbp-payment_qr>img {
    margin: auto
}

.sbp-payment_disclaimer {
    color: #c6c6c8
}

.sbp [data-tooltip] {
    position: relative
}

.sbp [data-tooltip]::after {
    content: attr(data-tooltip);
    position: absolute;
    top: -24px;
    right: -24px;
    width: 150px;
    pointer-events: none;
    opacity: 0;
    transition: opacity .15s ease-in-out;
    display: block;
    font-size: 12px;
    line-height: 16px;
    background: #fefdcd;
    padding: 2px 2px;
    border: 1px solid silver;
    box-shadow: 2px 4px 5px rgba(0,0,0,.4)
}

.sbp [data-tooltip]:hover::after {
    opacity: 1
}

.popup-header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    position: relative;
    padding: 14px 40px 0;
    text-align: center
}

.popup-header-choice {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #d9d9df
}

.popup-header-choice__title {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500
}

.popup-inner-content {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%
}

.popup-discount {
    display: flex;
    justify-content: center;
    background-color: #fc0;
    padding: 8px 0;
    color: #262626;
    font-size: 12px;
    line-height: 16px
}

.popup-inner-content--hall .popup-close-icon {
    top: 24px
}

.popup-inner-content--order .popup-header,.popup-inner-content--payment .popup-header {
    border-bottom: 1px solid #e7e7ec;
    padding: 13px 28px 13px 46px
}

.popup-inner-content--order>.popup-footer {
    height: unset
}

.popup-content {
    flex: 1;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.popup-content--hall {
    background-color: #f9fafb
}

.popup-content--payment,.popup-content--order {
    padding: 0
}

.popup-content--payment {
    height: 100%
}

.popup-content--payment iframe {
    min-height: calc(100% - 54px);
    border: none
}

.popup-back-icon {
    position: absolute;
    top: calc(50% + 4px);
    left: 16px;
    color: #262626
}

.popup-cancel-icon {
    position: absolute;
    top: calc(50% - 12px);
    left: 16px
}

.popup-close-icon {
    position: absolute;
    top: calc(50% - 12px);
    right: 20px
}

.popup-close-icon__techcard {
    position: absolute;
    right: 16px
}

.popup-close-icon__photo {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #262626;
    line-height: 40px;
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: rgba(255,255,255,.8);
    position: absolute;
    right: 12px;
    top: 16px
}

.popup-close-icon__photo svg {
    padding-top: 1px
}

@media only screen and (max-width: 767px) {
    .popup-close-icon__photo {
        width:32px;
        height: 32px;
        line-height: 32px;
        right: 8px;
        top: 8px
    }
}

.popup-footer {
    flex-shrink: 0;
    display: flex;
    z-index: 12;
    background-color: #fff;
    border-top: 1px solid #e7e7ec;
    height: 72px
}

.popup-footer-col {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    background-color: #fff;
    overflow-x: scroll;
    overflow-y: hidden;
}

.popup-footer-col--end {
    justify-content: flex-end
}

.popup-footer-col--buttons {
    display: flex;
    flex-direction: row
}

@media only screen and (max-width: 767px) {
    .popup-footer-col--buttons {
        width:100%
    }
}

.popup-footer__add {
    width: 100%
}

.popup-container__photo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    max-height: 100%
}

.popup-container__photo img {
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100%
}

.popup-container__custom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: #fff;
    width: 480px;
    border-radius: 8px;
    max-height: 400px
}

@media only screen and (max-width: 767px) {
    .popup-container__custom {
        width:100%;
        margin: 0 28px;
        max-height: 460px
    }
}

.popup-container__custom-close {
    position: absolute;
    right: 10px;
    top: 10px;
    color: #b0b0b6
}

.popup-container__custom-title {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px;
    margin: 0 16px;
    text-align: center
}

.popup-container__custom-text {
    text-align: center;
    font-size: 15px;
    line-height: 20px;
    margin: 11px 16px 16px;
    overflow-y: auto
}

.popup-container__custom-button {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: 1px solid #b0b0b6;
    box-shadow: 0 1px 3px rgba(0,0,0,.4);
    padding: 16px 0
}

@media only screen and (max-width: 767px) {
    .popup-container__custom-button button {
        width:auto
    }
}

.popup-container__custom-checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 16px
}

.popup-container__custom-checkbox>* {
    cursor: pointer
}

.popup-container__custom-checkbox>input {
    width: 20px;
    height: 20px
}

.popup-container__custom-checkbox>span {
    font-size: 15px;
    margin-left: 8px
}

.popup-container__custom img {
    margin: 25px 0 8px;
    background-repeat: no-repeat;
    background-position: center;
    max-width: 100%
}

.responsive-popup_container {
    padding: 20px;
    background-color: rgb(255, 255, 255);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 375px;
    border-radius: 8px
}

.responsive-popup_container>div:not(:last-child) {
    margin-bottom: 20px
}

@media only screen and (max-width: 767px) {
    .responsive-popup_container {
        position:absolute;
        width: 100%;
        bottom: 0;
        border-radius: 8px 8px 0 0
    }
}

.responsive-popup_header {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.responsive-popup_header>div {
    font-size: 20px
}

.responsive-popup_body {
    font-size: 15px
}

.responsive-popup_button {
    color: #0a7aff !important
}

.responsive-popup_transition-enter {
    opacity: 0
}

.responsive-popup_transition-enter-active {
    opacity: 1;
    transition: opacity 200ms
}

.responsive-popup_transition-exit {
    opacity: 1
}

.responsive-popup_transition-exit-active {
    opacity: 0;
    transition: opacity 200ms
}

.responsive-popup_transition__mobile-enter {
    transform: translateY(100%)
}

.responsive-popup_transition__mobile-enter-active {
    transform: translateY(0);
    transition: transform 200ms
}

.responsive-popup_transition__mobile-exit {
    transform: translateY(0)
}

.responsive-popup_transition__mobile-exit-active {
    transform: translateY(100%);
    transition: transform 200ms
}

.text--bold {
    font-weight: bold
}

.text--xx-small {
    font-size: 11px
}

.text--x-small {
    font-size: 12px
}

.text--small {
    font-size: 13px
}

.text--medium {
    font-size: 15px
}

.text--large {
    font-size: 20px
}

.text--x-large {
    font-size: 22px
}

.text--xx-large {
    font-size: 34px
}

.text--white {
    color: #fff
}

.text--lightgray {
    color: #d9d9df
}

.text--gray {
    color: #727276
}

.text--uppercase {
    text-transform: uppercase
}

.hall-schema {
    flex-shrink: 0;
    display: inline-block;
    position: relative;
    /* margin: 50px 0 0; */
    transition: transform .3s,opacity .3s;
    /* transform: rotate(180deg); */
}

.hall-schema_tiers__container {
    width: 100%;
    height: 100%;
    background: #efeff4
}

.hall-schema__remaining-seats-info {
    margin-right: 16px;
    font-weight: 400;
    color: #727276
}

.hall-schema__sum {
    font-size: 17px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding-right: 24px
}

@media only screen and (max-width: 767px) {
    .hall-schema__sum {
        width:100%;
        padding: 0 0 7px;
        font-size: 13px
    }
}

.hall-schema__sum-final {
    display: flex;
    justify-content: flex-end;
    font-weight: 500;
    line-height: 20px
}

@media only screen and (max-width: 767px) {
    .hall-schema__sum-final {
        justify-content:space-between
    }
}

.hall-schema__sum-difference {
    display: flex;
    flex-direction: column;
    color: #ff3b30
}

@media only screen and (max-width: 767px) {
    .hall-schema__sum-difference {
        flex-direction:row-reverse
    }
}

.hall-schema__sum-old {
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    line-height: 16px;
    color: #262626;
    text-decoration: line-through;
    text-decoration-color: #ff3b30;
    -moz-text-decoration-color: #ff3b30
}

@media only screen and (max-width: 767px) {
    .hall-schema__sum-old {
        padding:3px 7px 0 0;
        font-size: 11px
    }
}

.hall-schema-container {
    --schemaScaleCoeficient: 1;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    transform: scale(var(--schemaScaleCoeficient))
}

.hall-schema-container--disabled .hall-schema-seat:not(.hall-schema-seat--chosen,.hall-schema-seat--not-available),.hall-schema-container--disabled .hall-schema-sofa-seat:not(.hall-schema-sofa-seat--chosen,.hall-schema-sofa-seat--not-available) {
    opacity: .6
}

.hall-schema-seats-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    text-align: center
}

.hall-schema-row {
    font-size: 15px;
    position: absolute;
    width: 30px;
    height: 30px;
    padding: 10px 0 0 6px;
    color: #727276;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.hall-schema__chosen-seats-error {
    font-size: 13px;
    line-height: 30px;
    position: absolute;
    top: 6px;
    left: 50%;
    z-index: 1;
    border-radius: 4px;
    padding: 0 8px;
    background-color: rgba(0,0,0,.8);
    color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    white-space: nowrap;
    transform: translate(-50%, 0)
}

.hall-schema-seat {
    font-size: 20px;
    position: absolute;
    border-radius: 5px;
    background-color: #a4ce36;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.hall-schema-seat__place {
    line-height: 36px;
    opacity: 0;
    /* transform: rotate(180deg); */
}

.hall-schema-seat__tooltip-content {
    font-size: 15px;
    line-height: 26px;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 100%;
    left: 50%;
    z-index: 10010;
    margin-bottom: 16px;
    border-radius: 4px;
    padding: 6px 20px;
    background: rgba(0,0,0,.8);
    color: #fff;
    opacity: 0;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.3);
    text-align: center;
    white-space: nowrap;
    pointer-events: none;
    /* transform: translate(-50%, 200%) rotate(180deg); */
}

.hall-schema-seat__tooltip:hover .hall-schema-seat__tooltip-content {
    opacity: 10001
}

.hall-schema-seat--not-available {
    width: 12px !important;
    height: 12px !important;
    margin: 12px;
    border-radius: 50%;
    background-color: #a2a5b4 !important;
    pointer-events: none
}

.hall-schema-seat--not-allow-sale {
    width: 12px !important;
    height: 12px !important;
    margin: 12px;
    border-radius: 50%
}

.hall-schema-seat--handicap {
    /* background: url(/2a85f02ed3275544d9e6.svg) center center no-repeat; */
    background-size: cover;
    background-color: #06c
}

.hall-schema-seat--hovered {
    box-sizing: content-box;
    width: 40px !important;
    height: 40px !important;
    margin: -6px;
    border: 4px solid #fff;
    border-radius: 50%
}

.hall-schema-seat--hovered .hall-schema-seat__place {
    line-height: 48px;
    box-sizing: content-box;
    width: 48px;
    height: 48px;
    margin: -8px;
    border: 4px solid #a4ce36;
    border-radius: 50%;
    color: #262626;
    opacity: 1
}

.hall-schema-seat--chosen {
    box-sizing: content-box;
    width: 40px !important;
    height: 40px !important;
    margin: -6px;
    border: 4px solid #fff;
    border-radius: 50%;
    background-color: #262626 !important
}

.hall-schema-seat--chosen .hall-schema-seat__place {
    line-height: 48px;
    box-sizing: content-box;
    width: 48px;
    height: 48px;
    margin: -8px;
    border: 4px solid #a4ce36;
    border-radius: 50%;
    color: #fff;
    opacity: 1
}

.hall-schema-seat--occupied-social-distance {
    /* background: url(/92b26718ec030d70c83e.svg) no-repeat center; */
    background-color: #b0b0b6 !important;
    cursor: auto
}

.hall-schema-seat:hover {
    z-index: 10000 !important
}

.hall-schema-seat:hover:not(.hall-schema-seat--mobile,.hall-schema-seat--not-allow-sale,.hall-schema-seat--handicap,.hall-schema-seat--occupied-social-distance) {
    box-sizing: content-box;
    width: 40px !important;
    height: 40px !important;
    margin: -6px;
    border: 4px solid #fff;
    border-radius: 50%
}

.hall-schema-seat:hover:not(.hall-schema-seat--mobile,.hall-schema-seat--not-allow-sale,.hall-schema-seat--handicap,.hall-schema-seat--occupied-social-distance) .hall-schema-seat__place {
    line-height: 48px;
    box-sizing: content-box;
    width: 48px;
    height: 48px;
    margin: -8px;
    border: 4px solid #a4ce36;
    border-radius: 50%;
    opacity: 1
}

.hall-schema-sofa-seat {
    font-size: 20px;
    position: absolute;
    border-radius: 5px;
    background-color: #a4ce36;
    text-align: center;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.hall-schema-sofa-seat__place,.hall-schema-sofa-seat .hall-schema-seat__place {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    margin: -1px;
    border: 1px solid #a4ce36;
    border-radius: 3px;
    opacity: 0;
    box-shadow: 0 0 0 1px rgba(0,0,0,0)
}

.hall-schema-sofa-seat-wrapper:hover .hall-schema-sofa-seat .hall-schema-seat__tooltip-content {
    opacity: 1
}

.hall-schema-sofa-seat-wrapper:hover:not(.hall-schema-sofa-seat--mobile) .hall-schema-sofa-seat__place,.hall-schema-sofa-seat-wrapper:hover:not(.hall-schema-sofa-seat--mobile) .hall-schema-sofa-seat .hall-schema-seat__place {
    opacity: 1
}

.hall-schema-sofa-seat-wrapper:hover:not(.hall-schema-sofa-seat--mobile) .hall-schema-sofa-seat:last-child {
    z-index: 1000 !important
}

.hall-schema-sofa-seat-wrapper--handicap:hover .hall-schema-sofa-seat .hall-schema-seat__tooltip-content {
    opacity: 1
}

.hall-schema-sofa-seat-wrapper:hover:not(.hall-schema-sofa-seat--not-allow-sale) .hall-schema-sofa-seat:not(.hall-schema-sofa-seat--mobile,.hall-schema-sofa-seat--chosen,.hall-schema-sofa-seat--occupied-social-distance) {
    height: 38px !important;
    width: 38px !important;
    margin: 0 -1px
}

.hall-schema-sofa-seat-wrapper:hover:not(.hall-schema-sofa-seat--not-allow-sale) .hall-schema-sofa-seat:not(.hall-schema-sofa-seat--mobile,.hall-schema-sofa-seat--chosen,.hall-schema-sofa-seat--occupied-social-distance) .hall-schema-sofa-seat__place,.hall-schema-sofa-seat-wrapper:hover:not(.hall-schema-sofa-seat--not-allow-sale) .hall-schema-sofa-seat:not(.hall-schema-sofa-seat--mobile,.hall-schema-sofa-seat--chosen,.hall-schema-sofa-seat--occupied-social-distance) .hall-schema-seat__place {
    border: 2px solid #fff !important;
    width: 28px;
    height: 28px;
    margin: 3px;
    border-radius: 5px;
    line-height: 28px;
    overflow: hidden
}

.hall-schema-sofa-seat-wrapper--occupied-social-distance:hover .hall-schema-seat__place,.hall-schema-sofa-seat-wrapper--occupied-social-distance:hover .hall-schema-sofa-seat__place {
    border: 0
}

.hall-schema-sofa-seat--not-available {
    width: 12px !important;
    height: 12px !important;
    margin: 12px;
    border-radius: 50%;
    background-color: #a2a5b4 !important;
    pointer-events: none
}

.hall-schema-sofa-seat--occupied-social-distance {
    /* background: url(/92b26718ec030d70c83e.svg) no-repeat center; */
    background-color: #b0b0b6 !important;
    cursor: auto
}

.hall-schema-sofa-seat--chosen {
    border: 3px solid #a4ce36;
    box-sizing: content-box;
    margin: -3px;
    border-radius: 50%;
    background-color: #262626 !important
}

.hall-schema-sofa-seat--chosen .hall-schema-sofa-seat__place,.hall-schema-sofa-seat--chosen .hall-schema-seat__place {
    color: #fff;
    opacity: 1;
    border: 2px solid #fff !important;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin: 0;
    line-height: 32px
}

.hall-schema-popup {
    display: flex;
    border-bottom: 1px solid #d9d9df;
    height: 58px;
    margin-top: 6px;
    overflow: hidden
}

.hall-schema-popup_levels {
    border-bottom: none;
    height: 48px;
    z-index: 1
}

.hall-schema-popup_levels .hall-schema-popup-scroll {
    top: -8px;
    align-items: center;
    height: 60px
}

.hall-schema-popup__poster {
    flex-shrink: 0;
    width: 44px;
    height: 62px;
    margin-right: 10px;
    background-position: center center;
    background-size: cover
}

.hall-schema-popup__title {
    font-size: 15px;
    line-height: 20px;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 3px
}

.hall-schema-popup__date {
    margin-right: 6px
}

.hall-schema-popup__timer {
    position: absolute;
    right: 64px;
    text-align: right;
    white-space: nowrap
}

.hall-schema-popup__email {
    font-weight: normal
}

.hall-schema-popup__format {
    line-height: 17px;
    display: inline-block;
    margin: 0 2px;
    border: 1px solid #e7e7ec;
    background-color: #f9fafb;
    border-radius: 3px;
    padding: 2px 4px
}

.hall-schema-popup__subtitle {
    font-size: 15px;
    width: 100%;
    margin-top: 5px;
    overflow: hidden;
    color: #727276;
    text-overflow: ellipsis;
    white-space: nowrap
}

.hall-schema-popup--full {
    line-height: 48px;
    margin: 0 auto
}

.hall-schema-popup-panel {
    font-size: 12px;
    flex-shrink: 0;
    display: flex;
    color: #727276
}

.hall-schema-popup-panel-col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 32px 6px 32px
}

.hall-schema-popup-panel-col+.hall-schema-popup-panel-col {
    border-left: 1px solid #e7e7ec
}

.hall-schema-popup-seances {
    font-size: 12px;
    line-height: 20px;
    display: flex;
    position: relative;
    max-width: 80%;
    padding: 0 8px;
    overflow-x: auto;
    margin: 0 auto;
    height: 84px
}

.hall-schema-popup-scroll {
    position: absolute;
    width: 80px;
    height: 50px;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: #b0b0b6;
    background: linear-gradient(270deg, rgb(255, 255, 255) 38%, rgba(255, 255, 255, 0) 100%);
    transform: rotate(180deg)
}

.hall-schema-popup-scroll__right {
    left: auto;
    right: 0;
    transform: none;
    align-items: flex-start
}

.hall-schema-popup-scroll svg {
    margin-left: 15px
}

.hall-schema-error-text {
    width: 100%;
    margin: 10px 0;
    color: #ff3b30;
    text-align: center;
    white-space: normal
}

.hall-schema__screen {
    /* position: absolute; */
    /* width: 100%; */
    background: url("/public/image/screen.svg") 0 0 no-repeat;
    background-size: 100%;
    /* transform: rotate(180deg); */
}

.hall-legend {
    display: flex;
    align-self: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    border-radius: 18px;
    background-color: rgba(255,255,255,.8);
    padding: 2px 20px;
    z-index: 13;
    margin: 0 8px;
    transform: translateX(-8px)
}

@media only screen and (max-width: 767px) {
    
}

.hall-legend_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 8px
}

@media only screen and (max-width: 767px) {
    .hall-legend--with-discount {
        bottom:88px
    }
}

.hall-legend__seance-info span {
    display: inline-block
}

.hall-legend__seance-info span:not(:last-child) {
    margin-right: 8px
}

.hall-legend__prices {
    display: flex;
    flex-wrap: wrap;
    justify-content: center
}

.hall-legend__item {
    display: flex;
    align-items: center
}

.hall-legend__item+.hall-legend__item {
    margin-left: 16px
}

.hall-legend__color {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 6px 0 0;
    border-radius: 3px;
    background-color: #a4ce36
}

.hall-legend__color--disabled {
    border-radius: 50%;
    background-color: #a2a5b4
}

.hall-screen-container {
    margin-bottom: 20px;
    text-align: center
}

.hall-sector {
    position: absolute;
    z-index: -1;
    background-color: #fff;
    text-align: center;
    background-color: #f9fafb
}

.hall-sector__title {
    position: absolute;
    top: -20px;
    width: 100%
}

.hall-tier-list {
    display: flex;
    flex-direction: column
}

.hall-tier-list_selector {
    align-items: baseline
}

.hall-tier-list_button {
    z-index: 1;
    top: 64px;
    position: fixed !important;
    width: 100%
}

.hall-tier-list_button>.seance-item__container {
    padding: 6px 21px
}

.hall-tier-list_button .seance-item__time--hall {
    display: flex;
    justify-content: space-between;
    padding-right: 0
}

.hall-tier-list_item {
    display: flex;
    align-items: baseline;
    padding: 10px 0;
    font-size: 15px;
    line-height: 20px
}

.hall-tier-list_item:not(:last-child) {
    border-bottom: .3px solid;
    border-color: color(#3C3C43 a(29%))
}

.hall-tier-list_item__title {
    font-size: 17px;
    line-height: 22px;
    max-width: 50%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis
}

.hall-tier-list_item__free-seats {
    margin-left: 8px;
    font-size: 13px;
    line-height: 13px;
    color: #3c3c43;
    opacity: .6
}

.hall-tier-list_item__arrow-down {
    flex: 1;
    text-align: right
}

.hall-tier-list_item__checkmark {
    visibility: hidden;
    margin-left: auto
}

.hall-tier-list_item__selected>.hall-tier-list_item__title {
    color: #0a7aff
}

.hall-tier-list_item__selected>.hall-tier-list_item__checkmark {
    visibility: visible
}

.hall-tier-list_item__disabled {
    color: rgba(0,0,0,.3)
}

.payment-response {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 32px;
    text-align: left
}

.payment-response-error {
    flex-direction: column
}

.payment-response--in-content {
    width: calc(100% + 40px);
    margin: -20px
}

.payment-response__order-wrapper {
    display: flex
}

.payment-response__order {
    max-width: 460px
}

.payment-response__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 400px
}

.payment-response__error-text {
    font-weight: bold;
    line-height: 24px;
    text-align: center;
    margin: 8px 0
}

.payment-response__error-description {
    font-size: 16px;
    color: #727276;
    text-align: center;
    line-height: 21px;
    font-weight: 300
}

.payment-response__icon {
    width: 144px;
    height: 144px;
    margin: 0 36px 0 0
}

.payment-response__icon--qr-code {
    border: 1px solid #d9d9df;
    padding: 20px
}

.payment-response__icon-error {
    margin-bottom: 16px
}

.payment-response__icon--fail {
    /* background: url(/86f6a98a9662f19c54dd.svg) center center no-repeat */
}

.payment-response__icon--session {
    /* background: url(/a689ee853d7890002761.svg) center center no-repeat */
}

.payment-response__title {
    font-size: 28px;
    line-height: 34px;
    margin-top: 0
}

.payment-response__subtitle {
    font-size: 20px;
    line-height: 24px
}

.payment-response__info-text {
    line-height: 24px
}

.payment-response__info-text--bold {
    font-weight: bold
}

.payment-response__info-support {
    font-size: 14px;
    line-height: 20px;
    color: #262626
}

.payment-response__info-support a {
    color: #262626;
    text-decoration: underline
}

.payment-response__order-text {
    font-weight: bold;
    line-height: 38px
}

.payment-response__order-number {
    line-height: 38px;
    display: inline-block;
    border-radius: 5px;
    padding: 0 10px;
    background-color: #ffab00
}

.payment-response__certificate-sale-description .certificate-sale__rules-link {
    font-size: 15px;
    line-height: 20px
}

.payment-response_close-button {
    width: 100%
}

@media only screen and (max-width: 767px) {
    .payment-response__icon {
        margin:0 0 34px
    }

    .payment-response__order-wrapper {
        flex-direction: column;
        align-items: center;
        margin-top: 100px
    }

    .payment-response__order {
        text-align: center
    }
}

.hallscheme-button {
    line-height: 44px;
    display: inline-block;
    width: 44px;
    height: 44px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0,0,0,.2);
    text-align: center;
    transition: background .2s ease;
    cursor: pointer
}

.hallscheme-button__round {
    border-radius: 100%
}

.hallscheme-button__show-photo {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    margin-bottom: 8px
}

.hallscheme-button__active {
    background: #ffab00
}

.hallscheme-zooms {
    display: block;
    position: fixed;
    top: calc(50% - 68px);
    right: 0;
    z-index: 12;
    margin: 0;
    padding: 16px 8px;
    visibility: hidden;
    opacity: 0;
    list-style: none
}

.hallscheme-zooms__enabled {
    visibility: visible;
    opacity: 1;
    transition: opacity .2s ease .1s
}

.hallscheme-zooms_item {
    display: block;
    margin: 0;
    padding: 0
}

.hallscheme-zooms_item:first-child {
    margin-bottom: 8px
}

.hallscheme-zooms_item__zoomin .hallscheme-button {
    display: block;
    margin: 0;
    padding: 0
}

.hallscheme-zooms_icon {
    display: inline-block;
    position: relative;
    width: 16px;
    height: 16px;
    margin: 0;
    padding: 0;
    vertical-align: middle;
    overflow: hidden
}

.hallscheme-zooms_icon-hor,.hallscheme-zooms_icon-ver {
    display: block;
    position: absolute;
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: #262626
}

.hallscheme-zooms_icon-hor {
    top: 7px;
    left: 0;
    width: 16px;
    height: 2px
}

.hallscheme-zooms_icon-ver {
    top: 0;
    left: 7px;
    width: 2px;
    height: 16px
}

.hallscheme-zooms_icon__disabled .hallscheme-zooms_icon-hor,.hallscheme-zooms_icon__disabled .hallscheme-zooms_icon-ver {
    background: #d9d9df
}

.exit-question__popup {
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 392px;
    padding: 40px 24px 16px;
    background-color: #fff;
    text-align: center;
    z-index: 130
}

.exit-question__text {
    margin: 0 0 38px
}

.exit-question__continue {
    margin: 0 0 16px
}

.popup-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.7);
}

.popup-node {
    max-width: 100%;
    z-index: 121
}

@media only screen and (max-width: 767px) {
    .popup-node {
        width:100%
    }

    .popup-node>* {
        margin: 0 auto
    }
}

.releases-list {
    height: calc(100% - 47px);
    overflow: auto
}

.releases-list__empty {
    padding-left: 31px
}

@media only screen and (max-width: 767px) {
    .releases-list__title {
        font-size:20px;
        font-weight: 500;
    }
}

.release {
    display: flex;
    padding: 16px;
    background-color: #fff;
    transition: background-color .3s
}

.release__poster {
    flex: none;
    width: 64px;
    height: 91px;
    margin: 0 12px 0 0;
    overflow: hidden;
    border-radius: 3px
}

.release__poster-img {
    width: 100%;
    height: 100%;
    background-size: cover
}

.release__info {
    display: flex
}

.release__info-column {
    flex-direction: column
}

.release-schedule__cinema {
    display: flex;
    flex-direction: column;
    padding-bottom: 8px
}

.release-schedule__cinema-title {
    font-weight: 500;
    line-height: 28px
}

.release-schedule__cinema-address {
    line-height: 20px
}

.release-description {
    font-size: 14px;
    flex: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-top: 20px;
    margin-right: 6px;
    color: #262626
}

.release-description__title {
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 4px;
    letter-spacing: -0.58px;
    transition: color .3s;
    font-weight: 500
}

.release-description__genre {
    margin-bottom: 8px;
    letter-spacing: -0.34px;
    color: #727276
}

.release-description__age {
    font-size: 12px;
    margin-right: 8px;
    border: 1px solid #f2f2f5;
    border-radius: 4px;
    padding: 2px 4px;
    background-color: #f9fafb;
    letter-spacing: -0.34px;
    align-self: flex-start
}

.release-description__wrapper {
    margin-bottom: 8px;
    display: flex;
    width: 100%
}

.release-description__premiere {
    margin-bottom: 6px;
    display: flex;
    font-size: 12px;
    align-items: center
}

.release-trailer-item {
    font-size: 0;
    display: inline-block;
    position: relative;
    width: 264px;
    margin: 16px 16px 0;
    overflow: hidden;
    cursor: pointer
}

@media only screen and (max-width: 543px) {
    .release-trailer-item {
        width:calc(100% - 32px);
        height: auto
    }
}

.release-trailer-item__poster {
    max-width: 100%;
    transition: transform .3s
}

.release-trailer-item__play {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    width: 60px;
    height: 60px;
    border: 2px solid #fff;
    border-radius: 50%;
    background-color: rgba(0,0,0,.5);
    transition: transform .3s
}

.release-trailer-item:hover .release-trailer-item__play,.release-trailer-item:hover .release-trailer-item__poster {
    transform: scale(1.1)
}

.release:hover {
    background-color: #f9fafb
}

.release:hover .release-description__title {
    color: #ff3b30
}

.release-page {
    display: flex;
    height: calc(100% - 72px);
    border-top: 1px solid #e7e7ec
}

.release-page-wrapper {
    height: 100%
}

.release-page__poster {
    flex: none;
    width: 286px;
    height: 417px
}

.release-page__poster-wr {
    position: relative;
    z-index: 12;
    width: 333px;
    border-right: 1px solid #e7e7ec;
    padding: 16px 24px 0 22px;
    background-color: #fff
}

.release-page__poster-img {
    width: 100%;
    height: 100%;
    background-size: cover
}

.release-page__info {
    width: calc(100% - 334px);
    overflow: auto;
    background-color: #fff
}

.release-page__schedule {
    min-height: 88px;
    padding: 16px 16px 8px 16px;
    background-color: #fff;
    border-bottom: 1px solid #e7e7ec
}

.release-page__schedule-wrapper .menu-calendar {
    margin: -1px
}

.release-page__description {
    display: flex;
    flex-wrap: wrap;
    padding: 16px 24px 16px 16px
}

.release-page__column {
    min-width: 160px;
    max-width: 160px;
    margin: 0 20px 16px 0
}

.release-page__column--max-width-100 {
    max-width: 100%
}

.release-page__column--width-100 {
    width: 100%
}

.release-page__column__label {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 4px;
    color: #727276
}

.release-page__column__text {
    font-size: 14px;
    line-height: 18px;
    color: #262626
}

.release-page .release-description {
    flex: inherit;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    width: 286px;
    min-width: initial;
    margin-top: 20px
}

.release-page .release-description__title {
    font-size: 20px;
    width: 100%;
    margin-bottom: 8px;
    letter-spacing: -0.51px;
    font-weight: 500
}

.release-page .release-description__genre {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    letter-spacing: -0.34px;
    display: inline-block
}

.release-page .release-description__age {
    font-size: 12px;
    line-height: 14px;
    margin: 0 8px 8px 0;
    letter-spacing: -0.29px;
    display: inline-block
}

.seance-item--later {
    transition: color .3s,background-color .3s;
    border-radius: 4px;
    background-color: #e7e7ec;
    padding: 3px 0
}

.seance-item--later-buy {
    margin-bottom: 0;
    padding: 10px 20px
}

.seance-item--later:hover {
    background-color: #d9d9df
}

.seance-item--later:active {
    background-color: #c7c7cd
}

.seance-item--has-description .seance-item__vip {
    background-size: auto;
    width: 32px
}

.seance-item--has-description .seance-item__description {
    border-radius: 0 0 0 2px
}

.seance-item--has-description--all .seance-item__description {
    justify-content: space-between
}

.seance-item__time {
    padding: 5px 12px 3px 12px;
    line-height: 22px;
    font-size: 15px;
    color: #262626;
    font-weight: 500
}

.seance-item__time--hall {
    padding: 6px 12px;
    line-height: 20px;
    font-size: 15px;
    color: #262626;
    font-weight: 500
}

.seance-item__time--hall-chosen {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #3788d0;
    border-radius: 16px;
    padding: 6px 12px
}

.seance-item__time--hall-disabled {
    color: rgba(0,0,0,.3)
}

.seance-item__description {
    background-color: #e7e7ec;
    line-height: 13px;
    font-size: 11px;
    color: #4c4c4f;
    display: flex;
    justify-content: center;
    border-radius: 0 0 4px 4px;
    padding: 3px 4px 2px 4px
}

.seance-item__container {
    display: flex;
    border-radius: 4px;
    min-width: 64px;
    background-color: #f2f2f5;
    transition: color .3s,background-color .3s
}

.seance-item__container__item {
    flex-direction: column;
    width: 100%
}

.seance-item__container__item__has-vip {
    width: calc(100% - 26px)
}

.seance-item__container--hall {
    border-radius: 16px;
    /* display: inline-block;
    margin-right: 10px; */
}

.seance-item__vip {
    padding-right: 26px;
    width: 26px;
    border-radius: 0 4px 4px 0;
    /* background-image: url(/4540b31f92758dbe1e2e.svg); */
    background-repeat: no-repeat;
    background-position: 50%;
    background-color: #f2f2f5;
    background-size: 55%
}

.seance-item__vip .seance-item__description {
    border-radius: 0 0 0 2px
}

.seance-item__date {
    text-transform: lowercase
}

@media only screen and (max-width: 543px) {
    .seance-item__date {
        text-transform:initial
    }
}

@media only screen and (max-width: 543px) {
    .seance-item__text {
        display:none
    }
}

.seance-item__hall {
    font-size: 11px;
    line-height: 13px;
    color: #727276;
    margin-top: 3px
}

.seance-item__price {
    font-size: 11px;
    line-height: 13px;
    color: #727276;
    margin-top: 3px
}

.seance-item:hover .seance-item__container {
    background-color: #e7e7ec
}

.seance-item:hover .seance-item__description {
    background-color: #d9d9df;
    transition-duration: .3s
}

.seance-item:hover .seance-item__vip {
    background-color: #e7e7ec
}

.seance-item:active .seance-item__container {
    background-color: #d9d9df
}

.seance-item:active .seance-item__description {
    background-color: #c7c7cd;
    transition-duration: .3s
}

.seance-item:active .seance-item__vip {
    background-color: #d9d9df
}

.seance-item__hall-title {
    font-size: 12px;
    color: #727276
}

.time-tooltip {
    padding-left: 12px;
    padding-right: 12px
}

.calendar {
    flex-wrap: nowrap;
    position: relative;
    z-index: 120;
    background-color: #f2f2f5;
    box-shadow: inset 0 0 0 1px #e7e7ec;
    -ms-overflow-style: none
}

.calendar__days-list {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    z-index: 110;
    width: 100%;
    height: 0;
    overflow-x: auto;
    background-color: #f2f2f5;
    -webkit-transition: height .3s;
    -o-transition: height .3s;
    transition: height .3s;
}

.calendar__days-list a{
    text-decoration: none;
}

.calendar-menu--open .calendar__days-list {
    top: 0;
    height: 70px;
}

.calendar--release-page .calendar__days-list {
    height: 55px;
}

.calendar__days-list::-webkit-scrollbar {
    height: 0
}

.calendar__arrow {
    position: absolute;
}

.calendar__arrow svg {
    fill: #727276;
    transition: transform .3s
}

.calendar--open .calendar__arrow svg {
    transform: rotate(180deg)
}

.calendar--open .calendar__days-list {
    top: 0;
    height: 70px
}

.calendar-mobile {
    position: relative;
    margin: 0 -18px;
    background-color: #fff;
    cursor: pointer
}

.calendar-mobile__selected-day {
    font-size: 15px;
    line-height: 48px;
    position: relative;
    z-index: 111;
    height: 48px;
    border-top: 1px solid #e7e7ec;
    border-bottom: 1px solid #e7e7ec;
    background-color: #f9fafb;
    color: #262626;
    text-align: center
}

.calendar-mobile__arrow {
    position: absolute;
    top: 8px
}

.calendar-mobile__arrow svg {
    fill: #262626;
    transition: transform .3s
}

.calendar-mobile__days-list {
    display: flex;
    position: relative;
    top: -48px;
    z-index: 110;
    width: 100%;
    height: 0;
    margin: -1px 0 0;
    border-bottom: 1px solid #e7e7ec;
    padding: 0 18px;
    overflow-x: auto;
    background-color: #fff;
    transition: top .3s,height .3s
}

.calendar-mobile__date {
    font-size: 15px;
    flex: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #262626;
    cursor: pointer
}

.calendar-mobile__date+.calendar-mobile__date {
    margin-left: 18px
}

.calendar-mobile__date--day-week {
    font-size: 12px;
    margin-bottom: 4px;
    text-transform: uppercase
}

.calendar-mobile__date--day-month {
    line-height: 34px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    text-align: center;
    transition: background-color .3s,color .3s
}

.calendar-mobile__date--disabled {
    opacity: .3;
    cursor: default;
    pointer-events: none
}

.calendar-mobile__date--holiday {
    color: #ff3b30
}

.calendar-mobile--open .calendar-mobile__arrow svg {
    transform: rotate(180deg)
}

.calendar-mobile--open .calendar-mobile__days-list {
    top: 0;
    height: 70px
}

.calendar-mobile__empty {
    min-width: 18px;
    min-height: 1px
}

.calendar__date {
    line-height: 20px;
    font-size: .875rem;
    border: 1px solid #e7e7ec;
    -webkit-border-radius: 16px;
    border-radius: 16px;
    background-color: #fff;
    padding: 5px 15px;
    white-space: nowrap;
    cursor: pointer;
    -webkit-transition: background-color .3s,border-color .3s,-webkit-border-radius .3s;
    transition: background-color .3s,border-color .3s,-webkit-border-radius .3s;
    -o-transition: background-color .3s,border-radius .3s,border-color .3s;
    transition: background-color .3s,border-radius .3s,border-color .3s;
    transition: background-color .3s,border-radius .3s,border-color .3s,-webkit-border-radius .3s; 
}

.calendar__date--active,.calendar__date:hover {
    border-color: #e7e7ec;
}

.calendar__date--active,.calendar__date--active:hover {
    background-color: #fff;
    border-bottom-color: #fff
}

.calendar__date--active .calendar__date--day-week {
    color: #262626
}

.calendar__date--active,.calendar__date--disabled {
    cursor: default
}

.calendar__date--disabled {
    opacity: .3;
    cursor: default;
    pointer-events: none
}

.calendar__date--select-day {
    padding-right: 34px;
    cursor: pointer
}

.calendar__date+.calendar__date {
    margin-left: 4px
}

.calendar__date:last-child {
    margin-right: 6px;
    margin-left: 6px;
}

.calendar__empty {
    min-width: 24px;
    min-height: 1px
}

.calendar__arrow-left,.calendar__arrow-right {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 30px;
    height: calc(100% - 4px);
    opacity: 0;
    transition: left .3s,right .3s,opacity .3s
}

.calendar__arrow-left--open,.calendar__arrow-right--open {
    opacity: 1
}

.calendar--white {
    padding: 12px 1px 0;
    background-color: #fff
}

.calendar--white .calendar__date:hover {
    background-color: #fff
}

.scalable__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #f9fafb
}

.scalable__children-wrapper {
    height: 100%;
    overflow: hidden;
}

.scalable--with-cursor {
    cursor: move
}

.scalable__container {
    --zoom: 1;
    --movementX: 0;
    --movementY: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
    height: 100%;
    transform: scale(var(--zoom)) translate3d(calc(var(--movementX) / var(--zoom)), calc(var(--movementY) / var(--zoom)), 0);
    transform-origin: 0 0
}

.scalable__controlls {
    position: absolute;
    top: 50%;
    right: 0;
    padding-right: 16px;
    transform: translateY(-50%)
}

.scalable__scale-button {
    width: 50px;
    height: 50px;
    border: 1px solid #e7e7ec;
    border-radius: 100px;
    background-color: #fff;
    cursor: pointer;
    outline: none;
    transition: background-color .3s
}

.scalable__scale-button:active {
    background-color: #d9d9df
}

.g-desktop-hide {
    display: none !important
}

.g-desktop-hide--flex {
    display: none !important
}

.g-mobile-hide {
    display: block !important
}

.g-mobile-hide--flex {
    display: flex !important
}

.touch .hall-schema-container,.touch .hall-schema-seats-container {
    display: block
}

.touch .hall-schema-container {
    transform: initial
}

@media(max-width: 991px) {
    .overlay-content {
        width:100%
    }
}

@media only screen and (max-width: 767px) {
    .app {
        padding:0
    }

    .app-page {
        height: 100%
    }

    .app-header {
        padding: 14px 28px
    }

    .app-header__title {
        font-size: 15px;
        letter-spacing: -0.43px
    }

    .app-header__address {
        font-size: 12px;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        letter-spacing: -0.29px;
        white-space: nowrap
    }

    .app-afisha {
        height: calc(100% - 64px);
        border-top: none
    }

    .g-desktop-hide {
        display: block !important
    }

    .g-desktop-hide--flex {
        display: flex !important
    }

    .g-mobile-hide {
        display: none !important
    }

    .g-mobile-hide--flex {
        display: none !important
    }

    .calendar__days-list {
        /* display: none */
    }

    .calendar-mobile {
        margin: 0
    }

    .calendar__empty {
        min-width: 18px
    }

    .calendar__arrow-left,.calendar__arrow-right {
        display: none
    }

    .hall-schema-container,.hall-schema-seats-container {
        display: block
    }

    .hall-schema-container {
        transform: initial
    }

    .hall-schema-popup__title {
        font-size: 22px
    }

    .hall-schema-popup__timer {
        font-size: 15px;
        top: 11px;
        right: 38px
    }

    .hall-schema-popup__timer-time {
        font-size: inherit
    }

    .hall-schema-popup__timer-text {
        display: none
    }

    .hall-schema-popup-seances {
        padding-top: 8px
    }

    .hall-schema__chosen-seats-error {
        font-size: 12px
    }

    .payment-response {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center
    }

    .payment-response-error {
        align-items: center;
        justify-content: center
    }

    .payment-response__icon:not(.payment-response__icon--success,.payment-response__icon--empty) {
        width: calc(100% + 64px);
        height: 224px;
        min-height: 224px;
        margin: 0 -32px 16px;
        background-color: #f9fafb;
        background-position: 32px 64px
    }

    .payment-response--in-content .payment-response__icon {
        height: 150px
    }

    .overlay-container {
        padding: 0
    }

    .popup {
        width: 100%
    }

    .popup-footer {
        flex-direction: column
    }

    .popup-footer-col {
        padding: 8px 16px;
    }

    .popup-footer--payment {
        display: none
    }

    .popup-cancel-icon {
        top: 50%
    }

    .popup-back-icon {
        left: 2px
    }

    .popup-close-icon {
        right: 6px
    }

    .popup-inner-content--hall .popup-close-icon,.popup-inner-content--order .popup-close-icon,.popup-inner-content--payment .popup-close-icon {
        top: 20px;
        right: 6px
    }

    .popup-inner-content--payment .popup-close-icon {
        top: 18px
    }

    .popup-inner-content--order .popup-cancel-icon,.popup-inner-content--payment .popup-cancel-icon {
        top: 16px;
        left: 2px
    }

    .popup-inner-content--order .popup-header,.popup-inner-content--payment .popup-header {
        border-bottom: 1px solid #e7e7ec;
        padding: 10px 30px
    }

    .popup-inner-content--order .popup-cancel-icon {
        top: 6px
    }

    .popup-inner-content--order .popup-close-icon {
        top: 8px
    }

    .popup-content--payment {
        padding: 0;
        overflow: auto;
        -webkit-overflow-scrolling: touch
    }

    .popup-content--payment iframe {
        width: calc(100% - 1px)
    }

    .hall-schema-popup {
        height: auto;
        margin-top: 0
    }

    .hall-schema-popup__poster {
        display: none
    }

    .hall-schema-popup__title {
        font-size: 15px;
        letter-spacing: -0.43px
    }

    .hall-schema-popup__format {
        line-height: 14px;
        border-radius: 4px
    }

    .hall-schema-popup-panel-col {
        font-size: 12px;
        padding: 0 0 6px;
        letter-spacing: -0.29px
    }

    .hall-schema-popup-seances {
        height: auto;
        min-height: 55px
    }

    .order-poster {
        width: 60px;
        height: 88px;
        margin-right: 14px
    }

    .order-poster__wr {
        width: auto;
        border: none;
        padding: 0;
        display: flex
    }

    .order-info__release {
        display: flex
    }

    .order-info__release-title {
        font-size: 20px;
        letter-spacing: -0.51px
    }

    .order-info__release-hall,.order-info__release-format {
        font-size: 12px;
        line-height: 14px;
        letter-spacing: -0.29px
    }

    .order-info__date {
        font-size: 12px;
        letter-spacing: -0.29px
    }

    .order-email {
        padding: 12px
    }

    .button {
        width: 100%
    }

    .release__info {
        flex-direction: column
    }

    .release__poster {
        width: 60px;
        height: 88px;
        margin-right: 14px
    }

    .release-description {
        flex: inherit;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-content: baseline;
        width: auto;
        min-width: initial
    }

    .release-description__wrapper {
        margin-bottom: 10px
    }

    .release-description__title {
        font-size: 20px;
        width: 100%;
        margin-bottom: 8px;
        letter-spacing: -0.51px
    }

    .release-description__genre {
        font-size: 14px;
        width: calc(100% - 42px);
        margin-bottom: 8px;
        letter-spacing: -0.34px
    }

    .release-description__age {
        font-size: 12px;
        line-height: 14px;
        margin: 0 8px 8px 0;
        letter-spacing: -0.29px;
        align-self: center
    }

    .release-page {
        flex-direction: column;
        height: calc(100% - 64px);
        border: none;
        padding: 0;
        overflow: auto
    }

    .release-page__info {
        width: 100%;
        background-color: #fff
    }

    .release-page__poster {
        width: 60px;
        height: 88px;
        margin-right: 14px
    }

    .release-page__poster-wr {
        display: flex;
        width: auto;
        min-height: 125px;
        border-right: none;
        border-top: 1px solid #e7e7ec;
        padding: 16px 16px 16px 20px
    }

    .release-page__schedule {
        padding: 20px 6px 12px 20px
    }

    .release-page__description {
        max-width: 100%;
        padding: 14px 20px 0;
        background-color: #fff
    }

    .release-page__column {
        min-width: 120px;
        max-width: 120px
    }

    .release-page__column--max-width-100 {
        max-width: 100%
    }

    .release-page .release-description {
        margin: 0
    }

    .scalable__children-wrapper {
        overflow: auto
    }
}

.product {
    display: flex;
    border-top: 1px solid #e7e7ec;
    padding: 16px;
    background-color: #fff
}

.product:last-child {
    border-bottom: 1px solid #e7e7ec
}

.product-width {
    width: 100%
}

.product-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 148px)
}

@media only screen and (max-width: 767px) {
    .product-wrapper {
        height:100%
    }
}

.product-wrapper__height {
    height: 100%
}

.product-wrapper__subsale {
    height: calc(100% - 125px)
}

.product-button {
    font-size: 15px;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 40px;
    border-radius: 4px
}

.product-sum {
    margin-left: 16px;
    font-size: 15px
}

.product__chosen {
    background-color: #f9fafb;
    border-radius: 8px;
    padding: 16px;
    margin: 0 16px 16px 16px
}

.product__chosen-items {
    display: flex;
    flex-direction: column;
    width: 95%
}

.product__chosen-item {
    font-size: 13px;
    line-height: 18px
}

.product-button-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 120px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f2f2f5
}

.product-button-group__item {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 56px;
    height: 40px
}

.product-button-group__item:first-child {
    font-size: 22px;
    display: block;
    width: 32px;
    border: none;
    background-color: #0a7aff;
    color: #fff;
    line-height: 25px
}

.product-button-group__item:first-child:hover {
    background-color: color(#3788D0 a(80%))
}

.product-button-group__item:first-child:active {
    background-color: color(#3788D0 a(90%))
}

.product-button-group__item:last-child {
    font-size: 22px;
    display: block;
    width: 32px;
    border: none;
    background-color: #0a7aff;
    color: #fff;
    line-height: 25px
}

.product-button-group__item:last-child:hover {
    background-color: color(#3788D0 a(80%))
}

.product-button-group__item:last-child:active {
    background-color: color(#3788D0 a(90%))
}

.product-page {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #efeff4
}

@media only screen and (min-width: 767px) {
    .product-page {
        overflow:hidden
    }

    .product-page:hover {
        overflow-y: auto
    }

    .product-page:hover .product-page_description {
        width: 343px
    }
}

.product-page_description {
    font-size: 14px;
    display: flex;
    padding: 12px 12px 12px 16px;
    border: 1px solid #e7e7ec;
    border-radius: 8px;
    margin: 16px;
    background-color: #fff;
    justify-content: space-between;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

.product-page_description__box-img {
    width: 40px;
    margin-left: 8px;
    line-height: 0
}

.product-page_description__box-img img {
    width: 40px;
    height: 40px
}

.product-page_description__text p {
    margin: 0;
    font-size: 13px;
    line-height: 18px
}

.product__total-sum {
    font-size: 13px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 8px
}

.product__total-sum--order {
    align-items: flex-end;
    flex-direction: column;
    font-weight: 500
}

@media only screen and (max-width: 767px) {
    .product__total-sum--order {
        flex-direction:row
    }
}

.product__final-sum {
    display: flex;
    flex-direction: row;
    font-weight: 500;
    line-height: 20px;
    justify-content: space-between
}

.product__final-sum-difference {
    display: flex;
    color: #ff3b30
}

.product__final-sum-old {
    display: flex;
    justify-content: flex-end;
    font-size: 11px;
    color: #262626;
    line-height: 16px;
    padding: 3px 7px 0 0;
    text-decoration: line-through;
    text-decoration-color: #ff3b30;
    -moz-text-decoration-color: #ff3b30
}

.product__box-img {
    width: 88px;
    height: 88px;
    margin-right: 16px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #e7e7ec;
    background-color: #f9fafb;
    border-radius: 4px
}

.product__content {
    width: calc(100% - 88px);
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.product__content__title {
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 4px
}

.product__content__description {
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px;
    color: #727276
}

.product__content__order {
    display: flex;
    align-items: center
}

.product-poster {
    flex: none;
    width: 286px;
    height: 417px
}

.product-poster__wr {
    position: relative;
    z-index: 12;
    width: 333px;
    border-right: 1px solid #e7e7ec;
    padding: 16px 24px 0 22px;
    background-color: #fff
}

.product-poster--mobile {
    display: none
}

.product-poster__img {
    width: 100%;
    height: 100%;
    background-size: cover
}

.product__confidentiality {
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;
    color: #727276
}

.product__confidentiality--mobile {
    margin-top: 8px;
    text-align: center
}

.product__confidentiality__link {
    color: #727276;
    text-decoration: underline
}

.product-footer {
    flex-direction: column;
    padding: 8px 16px
}

.product-modification {
    display: flex;
    align-items: center;
    flex-direction: column
}

.product__checkmark {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 24px;
    height: 23px;
    margin-left: 4px;
    padding-bottom: 1px;
    background-color: #2db745;
    color: #fff
}

.product__order {
    display: flex
}

.product__price {
    padding-left: 8px;
    min-width: 55px;
    text-align: right
}

.white-space--nowrap {
    white-space: nowrap
}

.logger-overlay {
    position: fixed;
    bottom: 34px;
    right: 24px;
    width: 296px;
    z-index: 15;
    display: flex;
    flex-direction: column-reverse
}

.logger-overlay__hall {
    bottom: 106px;
    z-index: 13
}

@media only screen and (max-width: 767px) {
    .logger-overlay {
        position:fixed;
        width: calc(100vw - 24px);
        left: 12px;
        right: 12px;
        bottom: 95px
    }
}

.logger-log {
    width: 100%;
    padding: 12px;
    margin-bottom: 8px;
    background: rgba(37,37,37,.8);
    color: #fff;
    border-radius: 8px;
    font-size: 13px;
    line-height: 16px;
    display: flex;
    transition-duration: .3s;
    position: relative;
    padding-right: 36px
}

.logger-log--inline {
    color: #262626;
    background: #fff;
    min-width: 300px;
    max-width: 300px;
    font-size: 15px;
    line-height: 20px;
    box-shadow: 0px 0px 4px rgba(0,0,0,.15);
    padding: 12px;
    margin: 0 16px 0 0;
    align-self: center
}

.logger-log__icon {
    width: 40px;
    height: 40px;
    margin-right: 12px
}

.logger-log__close {
    color: rgba(255,255,255,.8);
    position: absolute;
    top: 9px;
    right: 9px
}

.logger-log__close svg {
    height: 17px;
    width: 17px
}

.logger-log__description {
    margin-bottom: 10px
}

.logger-log_test {
    display: flex
}

.logger-wrapper {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px;
    overflow-x: auto
}

.logger-enter {
    opacity: .01;
    transform: scale(0.9) translateX(50%)
}

.logger-active {
    opacity: 1;
    transform: scale(1) translateX(0%);
    transition: all 300ms ease-out
}

.logger-exit {
    opacity: 1;
    transform: scale(1) translateX(0%)
}

.logger-exit-active {
    opacity: .01;
    transform: scale(0.9) translateX(50%);
    transition: all 300ms ease-out
}

.law-documents__switch-menu {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    padding: 12px 16px;
    overflow-x: auto;
    white-space: nowrap;
    background-color: #f9fafb
}

.law-documents__menu-control {
    display: inline-block;
    padding: 0 21px;
    border-radius: 16px;
    background-color: #e7e7ec;
    color: #262626;
    font-size: 15px;
    line-height: 32px;
    transition: .2s ease-in-out
}

.law-documents__menu-control--active {
    background-color: #3788d0;
    color: #fff
}

.law-documents__menu-control:not(:last-child) {
    margin-right: 4px
}

.law-documents__menu-control:hover {
    cursor: pointer;
    opacity: .9
}

.law-documents__content {
    background-color: #fff;
    padding: 16px;
    line-height: 1.6em;
    font-size: 14px;
    overflow: auto
}

.law-documents__content.s-law-documents-content h2 {
    margin: 0;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px
}

.law-documents__content.s-law-documents-content ul {
    padding: 0
}

.law-documents__content.s-law-documents-content ul li {
    list-style-position: inside;
    margin-bottom: 12px
}

.law-documents__content.s-law-documents-content ul li li {
    padding-left: 16px
}

.law-documents__content.s-law-documents-content ul li li:first-child {
    margin-top: 12px
}

.details-table {
    border: 1px solid #3c3c43;
    border-collapse: collapse
}

.details-table td {
    padding: 5px;
    border: 1px solid #3c3c43
}

@supports(-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display:inline-block;
        -webkit-appearance: -apple-pay-button;
        border-radius: 4px;
        width: 100%;
        height: 100%;
        margin-bottom: 8px
    }

    .apple-pay-button-black {
        -apple-pay-button-style: #000
    }
}

@supports not (-webkit-appearance: -apple-pay-button) {
    .apple-pay-button {
        display:inline-block;
        background-size: 100% 60%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        border-radius: 4px;
        padding: 0;
        box-sizing: border-box;
        min-width: 100%;
        height: 100%;
        margin-bottom: 8px
    }

    .apple-pay-button-black {
        background-image: -webkit-named-image(apple-pay-logo-white);
        background-color: #000
    }
}

.google-pay-container {
    width: 100%;
    height: 100%
}

.google-pay-container>div {
    height: 100%
}

.gpay-button.black.short {
    width: 100%;
    height: 100%
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,.4);
    z-index: 14;
    transition-duration: .3s
}

@media only screen and (max-width: 767px) {
    .overlay {
        bottom:0
    }
}

.overlay-techcard {
    width: 375px;
    left: auto
}

@media only screen and (max-width: 767px) {
    .overlay-techcard {
        width:100%
    }
}

.overlay-order {
    background-color: #fff;
    width: 300px;
    transition-duration: .3s;
    z-index: 14;
    position: fixed;
    right: 296px;
    top: 15px;
}

@media only screen and (max-width: 767px) {
    .overlay-order {
        display:flex;
        flex-direction: column;
        width: 100%;
        bottom: 0px;
        right: 0;
        top: 0;
    }
}

.overlay-header {
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    padding: 16px;
    border-bottom: 1px solid #e7e7ec
}

.overlay-header__info-text {
    font-size: 15px;
    line-height: 20px;
    padding-bottom: 4px;
    text-align: right
}

.overlay-header__info-step {
    font-size: 12px;
    line-height: 16px;
    color: #727276;
    text-align: right
}

.overlay-header__progress {
    height: 48px;
    width: 48px;
    font-size: 11px
}

.overlay-header__progress svg {
    transform: rotate(-90deg)
}

.overlay-header__progress-circle {
    fill: none;
    stroke-linecap: round;
    stroke: #4cd964
}

.overlay-header__progress--step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    top: -48px;
    height: 100%
}

.overlay-header__progress--step-number {
    font-size: 17px;
    font-weight: 600;
    line-height: 22px
}

.overlay-content {
    height: calc(100% - 146px)
}

.overlay-content__order {
    height: calc(100% - 81px)
}

.overlay-buttons {
    display: flex;
    width: 100%
}

.overlay-buttons__back {
    width: 30%
}

.overlay-buttons__next {
    width: 70%;
    padding-left: 8px
}

.overlay-choice {
    background-color: #fff;
    transition-duration: .3s;
    z-index: 14;
    top: 40px;
    position: fixed;
    bottom: 30px;
    width: 375px;
    border-radius: 8px 8px 0 0;
    right: 0
}

@media only screen and (max-width: 767px) {
    .overlay-choice {
        width:100%;
        bottom: 0
    }
}

.overlay__photo {
    background-color: rgba(0,0,0,.8)
}

.overlay-sidebar-enter {
    transform: translateX(100%)
}

.overlay-sidebar-active {
    transform: translateX(0%);
    transition: all 300ms ease-out
}

.overlay-sidebar-exit {
    transform: translateX(0%)
}

.overlay-sidebar-exit-active {
    transform: translateX(100%);
    transition: all 300ms ease-out
}

.choice-techcard {
    height: 100%
}

.choice-techcard .product-wrapper {
    height: calc(100% - 93px)
}

.techcard {
    background-color: #fff
}

.techcard__content {
    display: flex;
    flex-direction: column
}

.techcard-selected {
    background-color: #fff
}

.scheme {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #e7e7ec;
    padding: 16px;
    background-color: #fff
}

.scheme--chosen {
    background-color: #f9fafb
}

.scheme__content {
    display: flex
}

.scheme__content__number {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border: 3px solid #2db745;
    width: 33px;
    height: 32px;
    padding-bottom: 1px;
    margin-right: 8px;
    font-weight: 500;
    background-color: #fff
}

.scheme__content__number-chosen {
    background-color: #2db745;
    color: #fff;
    font-size: 25px;
    font-weight: 300
}

.scheme__content__info {
    width: 90%
}

.scheme__content__title {
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 2px
}

.scheme__content__min {
    font-size: 12px;
    line-height: 16px;
    color: #727276;
    margin-bottom: 8px
}

.scheme__content__choice {
    display: flex;
    justify-content: space-between
}

.scheme__content__change-button {
    width: 110px
}

.scheme__content__order {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    overflow-x: scroll
}

.scheme__images {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap
}

.scheme__images-order {
    margin-top: 10px
}

.scheme__box-img {
    border-radius: 4px;
    width: 40px;
    height: 40px;
    margin: 0 8px 8px 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #e7e7ec;
    background-color: #f2f2f5
}

.modification {
    margin-right: 12px
}

.modification__box-img {
    border-radius: 4px;
    width: 120px;
    height: 120px;
    margin: 8px 0;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
    border: 1px solid #e7e7ec;
    background-color: #f2f2f5
}

.modification__box-img__chosen {
    border-radius: 4px;
    height: 118px;
    background-color: color(#2DB745 a(80%));
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 60px;
    line-height: 40px;
    font-weight: 300;
    font-family: system-ui
}

.modification__title {
    font-size: 13px;
    line-height: 18px;
    width: 120px;
    text-align: center;
    margin-bottom: 3px
}

.modification-sum {
    font-size: 11px;
    line-height: 13px;
    color: #b0b0b6
}

.modification__checkmark::after {
    content: "âœ“"
}

.modification__buttons {
    display: flex;
    flex-direction: row;
    position: relative
}

.modification__minus {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ff3b30;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    font-size: 22px;
    line-height: 10px;
    font-weight: 300;
    color: #fff;
    position: absolute;
    right: -3px;
    top: 5px
}

.popup-card {
    position: fixed;
    top: 40px;
    right: 0;
    bottom: 30px;
    width: 375px;
    border-radius: 8px 8px 0 0;
    background-color: #fff;
    z-index: 14;
    transition-duration: .3s
}

.popup-card__content {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%
}

.popup-card__header {
    position: relative;
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 16px;
    border-bottom: 1px solid #d9d9df;
    text-align: center
}

.popup-card__header-title {
    font-size: 17px;
    line-height: 22px;
    font-weight: 500
}

.popup-card__close-icon {
    position: absolute;
    right: 16px
}

.popup-card__body {
    flex: 1;
    overflow-y: auto
}

@media only screen and (max-width: 767px) {
    .popup-card {
        width:100%;
        bottom: 0
    }
}

.popup-card-enter {
    transform: translateY(100%)
}

.popup-card-active {
    transform: translateY(0%);
    transition: all 300ms ease-out
}

.popup-card-exit {
    transform: translateY(0%)
}

.popup-card-exit-active {
    transform: translateY(100%);
    transition: all 300ms ease-out
}

.popup-card-overlay {
    width: 375px;
    left: auto
}

@media only screen and (max-width: 767px) {
    .popup-card-overlay {
        width:100%
    }
}

.popup-card-overlay-enter {
    opacity: 0
}

.popup-card-overlay-active {
    opacity: 1;
    transition: opacity 300ms ease-out
}

.popup-card-overlay-exit {
    opacity: 1
}

.popup-card-overlay-exit-active {
    opacity: 0;
    transition: opacity 300ms ease-out
}

.certificate-sale {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative
}

.certificate-sale__content {
    flex: 1;
    background-color: #f2f2f5;
    overflow-y: auto
}

.certificate-sale__certificates-list {
    display: flex;
    flex-wrap: wrap;
    max-width: 1240px;
    margin: 0 auto;
    padding: 12px 8px
}

.certificate-sale__item {
    width: 33.3333333333%;
    padding: 0 8px;
    margin-bottom: 16px
}

@media only screen and (max-width: 767px) {
    .certificate-sale__item {
        width:50%
    }
}

@media only screen and (max-width: 543px) {
    .certificate-sale__item {
        width:100%
    }
}

.certificate-sale__item--added .certificate-card__image-wrapper::after {
    opacity: 1
}

.certificate-sale__rules-link {
    font-size: 12px;
    line-height: 16px;
    color: #727276;
    text-decoration: underline;
    transition: .2s ease-in-out
}

.certificate-sale__rules-link:hover {
    color: #a2a5b4
}

.certificate-card {
    display: flex;
    flex-direction: column;
    height: 390px;
    padding: 16px;
    background-color: #fff;
    border: 1px solid #e7e7ec;
    border-radius: 8px
}

.certificate-card__image-wrapper {
    position: relative;
    height: 245px;
    background-color: #d9d9df;
    overflow: hidden
}

.certificate-card__image-wrapper::after {
    content: "";
    position: absolute;
    right: 12px;
    top: 12px;
    width: 32px;
    height: 32px;
    /* background: url(/8c447cce1c1f38d2f03f.svg) no-repeat center/cover; */
    transition: .2s ease-in-out;
    opacity: 0
}

.certificate-card__image {
    width: 100%;
    height: 100%;
    background: no-repeat center/cover;
    transition: .2s ease-in-out
}

.certificate-card__image:hover {
    transform: scale(1.1)
}

.certificate-card__bonus {
    position: absolute;
    left: 12px;
    bottom: 12px
}

.certificate-card .bonus-patch {
    padding: 4px 8px;
    background: rgba(0,0,0,.6);
    border-radius: 4px;
    color: #fff
}

.certificate-card .bonus-patch__title {
    opacity: .6;
    font-size: 12px;
    line-height: 16px
}

.certificate-card .bonus-patch__value {
    font-size: 15px;
    line-height: 20px
}

.certificate-card__title {
    margin: 16px 0 0 0;
    font-size: 15px;
    line-height: 20px
}

.certificate-card__description {
    margin: 0;
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    color: #727276
}

.certificate-card__controls {
    margin-top: auto
}

.certificate-controls__buttons-group {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid rgba(0,0,0,0);
    width: 120px;
    border-radius: 4px;
    overflow: hidden;
    background-color: #f2f2f5
}

.certificate-controls__button {
    width: 32px;
    font-size: 15px;
    border: 0;
    background-color: #0a7aff;
    color: #fff;
    line-height: 40px;
    text-align: center;
    transition: .2s ease-in-out
}

.certificate-controls__button:hover {
    background-color: color(#3788D0 a(80%))
}

.certificate-controls__button:active {
    background-color: color(#3788D0 a(90%))
}

.certificate-controls__button--add {
    width: 120px;
    padding: 10px 16px;
    line-height: 20px;
    border-radius: 4px;
    border: 1px solid #c7c7cd;
    background-color: #fff;
    color: #262626
}

.certificate-controls__button--add:hover {
    background-color: #f2f2f5
}

.certificate-controls__button--add:active {
    background-color: #f2f2f5
}

.certificate-controls__price {
    display: inline-block;
    margin-left: 16px;
    font-variant-numeric: tabular-nums
}

.certificate-footer {
    display: flex;
    align-items: center;
    padding: 16px 24px;
    border-top: 1px solid #e7e7ec
}

.certificate-footer__price-wrapper {
    display: flex;
    justify-content: flex-end;
    flex: 1;
    margin-right: 16px;
    font-weight: 600;
    font-size: 17px
}

.certificate-footer__result-price {
    margin-left: 4px
}

.certificate-footer__sum {
    margin: 0;
    font-size: 17px;
    line-height: 22px
}

.certificate-footer .button {
    min-width: 205px;
    transition: .2s ease-in-out
}

@media only screen and (max-width: 767px) {
    .certificate-footer {
        flex-direction:column
    }

    .certificate-footer__price-wrapper {
        width: 100%;
        justify-content: space-between;
        margin-right: 0;
        margin-bottom: 12px
    }
}

.certificate-sidebar {
    z-index: 14
}

.certificate-sidebar__content-wrapper {
    height: 100%;
    overflow: hidden
}

.certificate-sidebar__cart {
    height: 100%;
    padding: 16px
}

.certificate-sidebar__cart-table-wrapper {
    max-height: calc(100% - 140px);
    overflow-y: auto;
    scrollbar-width: none
}

.certificate-sidebar__cart-table-wrapper::-webkit-scrollbar {
    display: none
}

.certificate-sidebar__cart-item-description {
    font-size: 13px;
    font-weight: 300;
    line-height: 18px;
    color: #727276
}

.certificate-sidebar__cart-notice {
    position: relative;
    padding: 12px 12px 12px 62px;
    margin-top: 16px;
    background-color: #f2f2f5;
    border-radius: 4px
}

.certificate-sidebar__cart-notice::before {
    content: "";
    position: absolute;
    left: 12px;
    /* background: url(/af94e9191c1cd4806721.svg) no-repeat center/cover; */
    width: 40px;
    height: 40px
}

.certificate-sidebar__cart-notice-info {
    margin: 0;
    font-size: 13px;
    font-weight: normal
}

.certificate-sidebar__footer .button {
    transition: .2s ease-in-out
}

.ticket-type__container {
    width: 375px;
    background: #efeff4;
    border-radius: 8px;
    position: relative
}

@media only screen and (max-width: 767px) {
    .ticket-type__container {
        position:absolute;
        width: 100%;
        bottom: 0;
        border-radius: 8px 8px 0 0
    }
}

.ticket-type__header {
    padding: 11px 0 9px 0;
    border-radius: 8px 8px 0 0;
    text-align: center;
    background: #fff
}

.ticket-type__title {
    font-size: 15px;
    line-height: 20px
}

.ticket-type__caption {
    font-size: 12px;
    line-height: 16px;
    color: #727276
}

.ticket-type__body {
    display: flex;
    flex-direction: column;
    padding: 16px
}

.ticket-type_button {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    transition: .1s background-color ease-in-out
}

.ticket-type_button__title {
    display: flex
}

.ticket-type_button__title>div:nth-child(2) {
    margin-left: 16px
}

.ticket-type_button:not(:last-child) {
    margin-bottom: 16px
}

.ticket-type__close-icon {
    position: absolute;
    right: 0;
    padding: 15px
}

.ticket-type__transition-enter {
    opacity: 0
}

.ticket-type__transition-enter-active {
    opacity: 1;
    transition: opacity 200ms
}

.ticket-type__transition-exit {
    opacity: 1
}

.ticket-type__transition-exit-active {
    opacity: 0;
    transition: opacity 200ms
}

.ticket-type__transition__mobile-enter {
    bottom: -500px
}

.ticket-type__transition__mobile-enter-active {
    bottom: 0;
    transition: bottom 200ms
}

.ticket-type__transition__mobile-exit {
    bottom: 0
}

.ticket-type__transition__mobile-exit-active {
    bottom: -500px;
    transition: bottom 200ms
}

.ticket-type-list {
    display: flex;
    flex-direction: column;
    padding: 16px
}

.ticket-type-list .popup-inner-content {
    background: rgba(249,249,249,.8)
}

.ticket-type-list .hall-legend_container {
    position: relative;
    margin: 16px;
    top: 0
}

.ticket-type-list .ticket-type__container {
    width: 100%;
    border-radius: 0
}

@media only screen and (max-width: 767px) {
    .ticket-type-list .ticket-type__container {
        position:relative
    }
}

.ticket-type-list .ticket-type-item {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400
}

.ticket-type-list .ticket-type-item__title {
    color: #262626
}

.ticket-type-list .ticket-type-item__title>div:nth-child(2) {
    margin-left: 16px
}

.ticket-type-list .ticket-type-item__description {
    display: flex;
    flex-direction: column
}

.ticket-type-list .ticket-type-item__price {
    color: #0a7aff
}

.ticket-type-list .ticket-type-item .product-button-group {
    width: auto
}

.ticket-type-list .ticket-type-item .product-button-group__item {
    width: 70px
}

.ticket-type-list .ticket-type-item .product-button-group__item:first-child,.ticket-type-list .ticket-type-item .product-button-group__item:last-child {
    width: 40px;
    background: #0a7aff
}

.ticket-type-list .ticket-type-item .product-button-group__item--disabled:last-child {
    background: #f2f2f5;
    color: #b0b0b6;
    cursor: auto;
    border-left: 1px solid rgba(60,60,67,.18)
}

.ticket-type-list .ticket-type-item:not(:last-child) {
    margin-bottom: 16px
}

.ticket-type-list .overlay-header {
    justify-content: flex-start
}

.ticket-type-list .overlay-header__progress {
    margin-right: 15px
}

.ticket-type-list .overlay-header__info-text,.ticket-type-list .overlay-header__info-step {
    text-align: left
}

.ticket-card_mobile__container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 72px;
    z-index: -1;
    background: #fff;
    transform: translateY(0);
    overflow-x: auto;
    border-top: 1px solid #e7e7ec
}

.ticket-card_mobile__container>div {
    display: flex
}

.ticket-card_mobile__container-enter {
    transform: translateY(0);
    height: 90px
}

.ticket-card_mobile__container-enter-done {
    transform: translateY(-80px);
    transition: .3s transform ease-in-out;
    height: 90px
}

.ticket-card_mobile__container-exit {
    transform: translateY(-80px);
    height: 90px
}

.ticket-card_mobile__container-exit-done {
    transform: translateY(0);
    transition: .3s transform ease-in-out;
    height: 90px
}

.ticket-card__container {
    display: flex;
    height: 72px;
    flex-grow: 1;
    margin: 0 16px
}

.ticket-card__item {
    position: relative;
    margin-left: -16px;
    min-width: 133px;
    background-color: #fff;
    border: 1px solid rgba(120,120,128,.2);
    border-radius: 8px 8px 0 0;
    padding: 8px 32px 8px 12px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    height: 92px;
    transform: translateY(8px);
    transition: .2s transform ease-in-out;
    white-space: nowrap
}

@media only screen and (max-width: 767px) {
    .ticket-card__item {
        display:inline-block;
        transform: unset;
        transition: unset;
        height: unset;
        border-radius: 8px;
        margin: 12px 6px
    }

    .ticket-card__item:first-child {
        margin-left: 16px
    }

    .ticket-card__item:last-child {
        margin-right: 16px
    }

    .ticket-card__item:not(.ticket-card__item:first-child) {
        margin-left: 0
    }
}

@media only screen and (min-width: 767px) {
    .ticket-card__item:hover {
        transform:translateY(-20px)
    }

    .ticket-card__item:hover .ticket-card__close-button {
        opacity: 1
    }
}

.ticket-card__item-transition-enter {
    opacity: 0;
    transform: translateY(50px)
}

.ticket-card__item-transition-enter-active {
    opacity: 1;
    transform: translateY(8px);
    transition: .3s all ease-in-out
}

.ticket-card__item-transition-exit {
    opacity: 1;
    transform: translateY(8px)
}

.ticket-card__item-transition-exit-active {
    opacity: 0;
    transform: translateY(50px);
    transition: .3s all ease-in-out
}

.ticket-card__ticket-type {
    font-weight: 400;
    color: #939499
}

.ticket-card__legend {
    position: relative;
    top: 1px;
    display: inline-block;
    margin-right: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%
}

.ticket-card__close-button {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    opacity: 0;
    transition: .2s opacity ease-in-out
}

@media only screen and (max-width: 767px) {
    .ticket-card__close-button {
        transition:unset;
        opacity: 1
    }
}

.ticket-card__mobile-button {
    display: flex;
    flex: 1;
    min-width: 90px;
    justify-content: space-between;
    padding: 7px 6px 7px 12px;
    margin-right: 8px
}

.ticket-card__mobile-button-arrow-enter {
    transform: rotate(0deg)
}

.ticket-card__mobile-button-arrow-enter-done {
    transform: rotate(180deg);
    transition: .3s transform ease-in-out
}

.ticket-card__mobile-button-arrow-exit {
    transform: rotate(180deg)
}

.ticket-card__mobile-button-arrow-exit-active {
    transform: rotate(0deg);
    transition: .3s transform ease-in-out
}

.ticket-card__mobile-button__content {
    display: flex;
    flex-direction: column
}

.ticket-card__mobile-button__content>span {
    font-size: 11px;
    line-height: 13px
}

.ticket-card__mobile-button__content>div {
    display: flex
}

.ticket-card__mobile-button__content>div>span {
    font-size: 15px;
    line-height: 20px;
    margin-left: 5px
}

.kiosk_container {
    width: 100%;
    height: 100%;
    display: flex;
    background: linear-gradient(120deg, #000 0%, #2E2B4E 100%);
    justify-content: center;
    padding: 0 120px;
    flex-direction: column;
    z-index: 10;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none
}

@media only screen and (max-width: 991px) {
    .kiosk_container {
        padding:0 60px
    }
}

.kiosk_header {
    margin: 32px 0;
    font-size: 72px;
    line-height: 108px;
    font-weight: 800;
    color: #fff
}

.kiosk_sbp-logo {
    width: 218px
}

.kiosk_continue-button {
    display: flex;
    align-items: center;
    font-size: 32px;
    padding: 32px 64px;
    height: initial;
    align-self: flex-start;
    border-radius: 8px;
    background-color: #0a7aff
}

.kiosk_qr-mockup-image {
    position: absolute;
    right: 0;
    max-width: 80%;
    max-height: 100%;
    z-index: -1
}

.tabs {
    width: 100%;
    background: rgba(118,118,128,.12);
    border-radius: 8px;
    display: flex;
    margin-bottom: 15px;
    justify-content: space-around;
    padding: 2px
}

.tabs__item {
    color: #262626;
    font-weight: 600;
    text-align: center;
    font-size: 13px;
    padding: 6px 8px
}

.tabs__item--active {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0px 3px 8px rgba(0,0,0,.12),0px 3px 1px rgba(0,0,0,.04)
}

.alice-carousel .animated {
    animation-fill-mode: both;
}

.alice-carousel .animated-out {
    z-index: 1;
}

.alice-carousel .fadeOut {
    animation-name: fadeOut;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        visibility: hidden;
    }
}

.alice-carousel {
    position: relative;
    width: 100%;
    margin: auto;
    direction: ltr;
}

.alice-carousel__wrapper {
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    box-sizing: border-box;
    width: 100%;
    height: auto;
}

.alice-carousel__stage {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    transform-style: flat;
    -webkit-transform-style: flat;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
}

.alice-carousel__stage-item {
    position: relative;
    display: inline-block;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    vertical-align: top;
    white-space: normal;
    line-height: 0;
}

.alice-carousel__stage-item * {
    line-height: initial;
}

.alice-carousel__stage-item.__hidden {
    opacity: 0;
    overflow: hidden;
}

.alice-carousel__prev-btn, .alice-carousel__next-btn {
    display: inline-block;
    box-sizing: border-box;
    width: 50%;
    padding: 10px 5px;
}

.alice-carousel__prev-btn [data-area]::after, .alice-carousel__next-btn [data-area]::after {
    position: relative;
    content: attr(data-area);
    text-transform: capitalize;
}

.alice-carousel__prev-btn {
    text-align: right;
}

.alice-carousel__prev-btn-item, .alice-carousel__next-btn-item {
    display: inline-block;
    cursor: pointer;
    padding: 5px;
    margin: 0;
    color: #465798;
}

.alice-carousel__prev-btn-item:hover, .alice-carousel__next-btn-item:hover {
    color: darkred;
}

.alice-carousel__prev-btn-item.__inactive, .alice-carousel__next-btn-item.__inactive {
    opacity: 0.4;
    pointer-events: none;
}

.alice-carousel__play-btn {
    position: absolute;
    top: 30px;
    left: 20px;
    display: inline-block;
}

.alice-carousel__play-btn:hover {
    cursor: pointer;
}

.alice-carousel__play-btn-wrapper {
    position: relative;
    width: 32px;
    height: 32px;
    padding: 10px;
    border-radius: 50%;
    background-color: #fff;
}

.alice-carousel__play-btn-item {
    position: absolute;
    width: 32px;
    height: 32px;
    cursor: pointer;
    border: 0;
    outline: none;
    background: transparent;
}

.alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
    position: absolute;
    pointer-events: none;
    display: block;
    width: 0;
    height: 0;
    content: "";
    transition: all 0.4s linear;
    border-width: 8px 0 8px 15px;
    border-style: solid;
    border-color: transparent;
    border-left-color: #465798;
}

.alice-carousel__play-btn-item::before {
    left: 5px;
    height: 14px;
}

.alice-carousel__play-btn-item::after {
    top: 7px;
    left: 18px;
}

.alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
    height: 30px;
    border-width: 0 0 0 10px;
}

.alice-carousel__play-btn-item.__pause::after {
    top: 0;
    left: 18px;
}

.alice-carousel__dots {
    margin: 30px 3px 5px;
    padding: 0;
    list-style: none;
    text-align: center;
}

.alice-carousel__dots > li {
    display: inline-block;
}

.alice-carousel__dots-item:not(.__custom) {
    width: 8px;
    height: 8px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #e0e4fb;
}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 20px;
}

.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
    background-color: #6e7ebc;
}

.alice-carousel__slide-info {
    position: absolute;
    top: 20px;
    right: 20px;
    display: inline-block;
    padding: 5px 10px;
    color: #465798;
    border-radius: 5px;
    background-color: rgba(224, 228, 251, 0.6);
}

.alice-carousel__slide-info-item {
    vertical-align: middle;
    line-height: 0;
}